
.dedicatedstuff {
 


  padding: 40px 17px 20px;
  margin: 1vh 0;
  background: #fff;
}

.heading-case{
    padding:30px 0px 0px 5px;
    font-size: 28px;
    font-weight: bold;
    font-family: 'PT Sans', sans-serif;
    text-align: center;
}

.heading-case1{
  margin-top: 12vh;
  padding:30px 0px 0px 5px;
  font-size: 28px;
  font-weight: bold;
  font-family: 'PT Sans', sans-serif;
  text-align: center;
}
.subheading-case{
    padding:10px 0px 0px 5px;
    font-size: 25px;
    font-family: 'PT Sans', sans-serif;
    text-align: center;
}

.content-case{
    padding:20px 15px 0px 5px;
    font-size: 16px;
    font-weight: 400;
    font-family:'Source Sans Pro',sans-serif; 
    text-align: left;
    margin-bottom: 20vh;
}

.second-subhead{
   
    font-size:14px;
    font-family:'Source Sans Pro',sans-serif; 
}
.second-head{
    color:black !important;
    font-weight:800;
    font-size:15px;
    font-family:'Source Sans Pro',sans-serif; 
}
.bottom-line{
  width: 53vw;
  background-color: #ece9e9;
  margin-left: -14px;
  margin-bottom: 2vh;
  border-radius: 3px;
}

.resourcepage-line{
  width: 761px;
    background-color: #f5f5f5;
    margin-left: -3px;
    margin-top: -41px;
    border-radius: 5px;
}

@media only screen and (min-width:'2560'){
  .resourcepage-line{
    width: 57vw;
      background-color: #f5f5f5;
      margin-left: -3px;
      margin-top: -41px;
      border-radius: 5px;
  }
}

.bottom-line1{
  width: 897px;
  background-color: #ece9e9;
  margin-top: -15px;
  margin-left: -14px;
  box-shadow: 1px 3px 7px 1px #e1e0e0;
  border-radius: 3px;
  position:absolute;
  z-index:70000;
}

.bottom-line2{
  width: 897px;
  background-color: #ece9e9;
  margin-top: -15px;
  margin-left: -14px;
  box-shadow: 1px 3px 7px 1px #e1e0e0;
  border-radius: 3px;
  position:absolute;
  z-index:70000;
  height: 5vh;
}
.modal-header1 {
  height: 670px;
  overflow-y: scroll;
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}


@media only screen and (min-width:1600px){
  .bottom-line1{
    width: 55vw;
    background-color: #f5f5f5;
    margin-bottom: 2vh;
    box-shadow: 1px 3px 7px 1px #e1e0e0;
    border-radius: 3px;
  }
}

@media only screen and (min-width:1920px){
  .bottom-line1{
    width: 45vw;
    background-color: #f5f5f5;
    margin-bottom: 2vh;
    box-shadow: 1px 3px 7px 1px #e1e0e0;
    border-radius: 3px;
  }
}

@media only screen and (min-width:2304px){
  .bottom-line1{
    width: 38vw;
    background-color: #f5f5f5;
    margin-bottom: 2vh;
    box-shadow: 1px 3px 7px 1px #e1e0e0;
    border-radius: 3px;
  }
}

@media only screen and (min-width:2560px){
  .bottom-line1{
    width: 35vw;
    background-color: #f5f5f5;
    margin-bottom: 2vh;
    box-shadow: 1px 3px 7px 1px #e1e0e0;
    border-radius: 3px;
  }
}

@media only screen and (min-width:3000px){
  .bottom-line1{
    width: 29vw;
    background-color: #f5f5f5;
    margin-bottom: 2vh;
    box-shadow: 1px 3px 7px 1px #e1e0e0;
    border-radius: 3px;
  }
}

@media only screen and (min-width:3840px){
  .bottom-line1{
    width: 22vw;
    background-color: #f5f5f5;
    margin-bottom: 2vh;
    box-shadow: 1px 3px 7px 1px #e1e0e0;
    border-radius: 3px;
  }
}
.bookmark1{
  cursor: pointer;
  float: right;
  margin: 2px 14px 0px 0;
  color: #8d8d8d;
  font-size: 20px;
}
.bookmark{
  cursor: pointer;
  float: right;
  margin: 2px 2vw 0 0;
  color: #8d8d8d;
  font-size: 20px;
}

.bookmark2{
  cursor: pointer;
  float: right;
  margin: 2px 2vw 0 0;
  color: #8d8d8d;
  font-size: 20px;
}

@media only screen and (min-width:1920px){
  .bookmark{
    cursor: pointer;
    float: right;
    margin: 2px 2vw 0 0;
    color: #8d8d8d;
    font-size: 20px;
    margin-top: 7px;
  }
}

@media only screen and (min-width:2304px){
  .set_margin{
    margin-left: 58vh !important;
  }
}

.set_margin{
  margin-left: 95vh;
}

.set-resmargin{
  margin-left: 2ve;
}

.copy{
  cursor: pointer;
  float: right;
  margin: 2px 2vw 0 0;
  color: #8d8d8d !important;
  font-size: 20px !important;  
}
.copy2{
  cursor: pointer;
  float: right;
  margin: 2px 2vw 0 0;
  color: #8d8d8d !important;
  font-size: 20px !important;  
}
.copy1{
  cursor: pointer;
  float: right;
  margin: 2px 2vw 0 0;
  color: #8d8d8d !important;
  font-size: 20px !important;  
}

.print{
  cursor: pointer;
  float: right;
  margin: 2px 32vw 0 0;
  color: #8d8d8d !important;
  font-size: 20px !important;  
}

@media only screen and (min-width:2880px){
  .print{
    cursor: pointer;
    float: right;
    margin: 2px 39vw 0 0;
    color: #8d8d8d !important;
    font-size: 20px !important;  
  }
}

@media only screen and (min-width:2560px){
  .print{
    cursor: pointer;
    float: right;
    margin: 2px 38vw 0 0;
    color: #8d8d8d !important;
    font-size: 20px !important;  
  }
}

.modalprint{
  cursor: pointer;
  float: right;
  margin: 2px 44vw 0 0;
  color: #8d8d8d !important;
  font-size: 20px !important;  
}

.printresource{
  cursor: pointer;
  float: right;
  margin: -29px 47vw 0 0;
  color: #8d8d8d !important;
  font-size: 20px !important;  
}
@media only screen and (min-width:1600px)
{  .printresource{
  cursor: pointer;
  float: right;
  margin: 2px 42vw 0 0;
  color: #8d8d8d !important;
  font-size: 20px !important;  
  }}

.share1{
  cursor: pointer;
  float: right;
  margin: 2px 1vw 0 0;
  color: #8d8d8d !important;
  font-size: 20px !important;  
}
.share{
  cursor: pointer;
  float: right;
  margin: 2px 2vw 0 0;
  color: #8d8d8d!important;
  font-size: 20px!important; 
}
.share2{
  cursor: pointer;
  float: right;
  margin: 1px 2vw 0 0;
  color: #8d8d8d!important;
  font-size: 20px!important;
}

@media only screen and (min-width:1920px){
  .share{
    cursor: pointer;
    float: right;
    margin: 2px 2vw 0 0;
    color: #8d8d8d !important;
    font-size: 20px !important; 
    margin-top: 7px 
  }
}

.shareall1{
  margin-top: -23vh;
  position: absolute;
  background-color: #f9f9f9;
  min-width: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  margin-left: 100vh
}
.shareall{
  position: absolute;
  background-color: #f9f9f9;
  min-width: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  margin-left: 100vh
}
.shareall2{
  position: absolute;
  background-color: #f9f9f9;
  min-width: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  margin-left: 89vh
}
.shareall3{
  position: absolute;
  background-color: #f9f9f9;
  min-width: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  margin-left: 105vh
}

@media only screen and (min-width:1920px){
  .shareall{
    position: absolute;
    background-color: #f9f9f9;
    min-width: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-left: 87vh
  }
}

@media only screen and (min-width:2304px){
  .shareall{
    position: absolute;
    background-color: #f9f9f9;
    min-width: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-left: 80vh
  } 
}

@media only screen and (min-width:2560px){
  .shareall{
    position: absolute;
    background-color: #f9f9f9;
    min-width: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-left: 79vh
  } 
}

@media only screen and (min-width:3000px){
  .shareall{
    position: absolute;
    background-color: #f9f9f9;
    min-width: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-left: 75vh
  } 
}
.share-option{
  width: auto;
  height: auto;
  /* background-color: white; */
  color: #8d8d8d;
  font-size: 26px;
  float: right;
  margin-right: 1vw;
  cursor: pointer;
  z-index: 1;
}

.drop{
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.2);
  width: 25vw;
  height: auto;
  border: 1px solid white !important;
  border-radius: 5px !important;
  /* display: block; */
}
.sub-cat:hover .dropdown{
  display: block;
}

.image-law{
  margin-top: 1vh;
  margin-left: 2vw;
  width: 3vw;
  height: 5vh;
  color:blueviolet !important;
}

.company{
  font-size: 17px;
    color: #636262;
    margin-left: 1vw;
}

.des-law{
  font-size: 13px;
  color: #9e9d9d;
  margin-left: 12vh;
  margin-top: 0vh
}

.button-color{
  background-color: #ece9e9;
  color: #8d8d8d
}
.content-box{
    overflow-y: scroll; 
    height:63vh;
}

.closetab{
  margin-top: -30px;
  cursor: pointer;
  float: right;
  color: grey;
  margin-right: 8px;
  float: right;
  position: relative;
}

.closetab1{
  margin-top: -30px;
  cursor: pointer;
  float: right;
  color: grey;
  margin-right: 8px;
  float: right;
  position: relative;
}


.moretab{
  margin-left:44vw;
}

.contenttag {
  margin-left:14px;
  margin-top: 10px;
  font-size: 15px;
  color: #353535;
  font-weight: 200;
  font-family:'Montserrat',sans-serif;
}

.tagname{
  margin-top:-12px;
  margin-bottom: 16px; 
}

.tagcont{
  margin-top: -4px;
}
.more{
  float: right;
  cursor: pointer;
  color: blue;
  text-align: center;
  font-size: 16px !important;
  margin-right: 1vw;
  position: relative;
}

.more1{
  float: right;
  cursor: pointer;
  color: blue;
  text-align: center;
  font-size: 16px !important;
  margin-right: 1vw;
  position: relative;
  margin-top: -2px;
  margin-left: -11px;
}

.more2{
  float: right;
    cursor: pointer;
    color: blue;
    text-align: center;
    font-size: 16px !important;
    margin-right: 1vw;
    position: relative;
    margin-top: -2px;
    margin-left: -8px;
}

@media only screen and (min-width:1920px){
  .more{
    float: right;
    cursor: pointer;
    color: blue;
    text-align: center;
    font-size: 16px !important;
    margin-right: 1vw;
    position: relative;
    margin-top:1px;
  }
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 11px solid #38bdd4;
  margin-top: -69vh;
  position: relative;
  opacity:1;
  margin-left: 49vw;
  transition:all 0.5s ease;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.arrow-up1 {
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 11px solid #38bdd4;
  margin-top: -59vh;
  position: absolute;
  opacity:1;
  margin-left: 63vw;
  transition:all 0.5s ease;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

@media only screen and (min-width:1600px){
  .arrow-up3{
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid #38bdd4;
    margin-top: -162vh;;
    position: absolute;
    opacity:1;
    margin-left: 53vw;
    transition:all 0.5s ease;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  }
}

.arrow-up3{
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 11px solid #38bdd4;
  margin-top: -221vh ;
  position: absolute;
  opacity:1;
  margin-left: 52vw ;
  transition:all 0.5s ease;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.arrow-up4{
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 11px solid #38bdd4;
  margin-top: -297vh;
  position: absolute;
  opacity:1;
  margin-left: 62vw;
  transition:all 0.5s ease;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}
@media only screen and (min-width:1920px){
  .arrow-up {
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid #38bdd4;
    margin-top: -67vh;
    position: absolute;
    opacity:1;
    margin-left: 50vw;
    transition:all 0.5s ease;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  }
}

.moreopt{
      
      padding: 15px;
      height: 300px;
      display: block;
      position: relative;
      background-color: #ffffff;
      min-width: 272px;
      box-shadow: 1px 2px 12px 0px rgba(0, 0, 0, 0.1);
      z-index: 1;
      cursor: pointer;
      border-radius: 5px;
}

.moreopt1{
  padding: 15px;
  height: auto;
  display: block;
  position: absolute;
  background-color: #ffffff;
  min-width: 274px;
  box-shadow: 1px 2px 12px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;
  margin-top: -58vh;
  margin-left: 25vw;
  cursor: pointer;
  border-radius: 5px;
}

@media only screen and (min-width:1600px){
  .moreopt2{
    padding: 15px;
    height: 300px;
    display: block;
    position: absolute;
    background-color: #ffffff;
    min-width: 274px;
    box-shadow: 1px 2px 12px 0px rgba(0, 0, 0, 0.1);
    z-index: 1;
    margin-top: -162vh !important;
    margin-left: 44vw !important;
    cursor: pointer;
    border-radius: 5px;
  }
}

.moreopt2{
  padding: 15px;
  height: 300px;
  display: block;
  position: absolute;
  background-color: #ffffff;
  min-width: 274px;
  box-shadow: 1px 2px 12px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;
  margin-top: -220vh;
  margin-left: 34vw;
  cursor: pointer;
  border-radius: 5px;
}

.moreopt3{
  padding: 15px;
  height: 300px;
  display: block;
  position: absolute;
  background-color: #ffffff;
  min-width: 274px;
  box-shadow: 1px 2px 12px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;
  margin-top: -296vh;
  margin-left: 44vw;
  cursor: pointer;
  border-radius: 5px;
}
@media only screen and (min-width:1600px){
  .moreopt{
    display: block;
    position: absolute;
    background-color: #ffffff;
    min-width: 174px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-top: -70vh;
    margin-left: 40vw;
    cursor: pointer;
  }
}
@media only screen and (min-width:1920px){
  .moreopt{
    display: block;
    position: absolute;
    background-color: #ffffff;
    min-width: 174px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-top: -68vh;
    margin-left: 43vw;
    cursor: pointer;
  }
}

@media only screen and (min-width:2304px){
  .moreopt{
    display: block;
    position: absolute;
    background-color: #ffffff;
    min-width: 174px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-top: -68vh;
    margin-left: 44vw;
    cursor: pointer;
  }
}
@media only screen and (min-width:2560px){
  .moreopt{
    display: block;
    position: absolute;
    background-color: #ffffff;
    min-width: 174px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-top: -66vh;
    margin-left: 45vw;
    cursor: pointer;
  }
}
.button-size{
    /* width: 75px;
    height: 35px;
    margin-right: 10px;

    text-align: center !important; */
    margin-top: 10px;
    margin-left: 2px;
    background-color: #66c0db;
    border: none;
    padding: 0px 7px ;
    text-align: center!important;
    display: inline-block;
    font-size: 10px;
    font-weight: 900;
    border-radius: 4px;
    color: #fff !important;
}

.button24 {
    margin-top: 6px;
    margin-left: 22px;
    width: 180px;
    padding: 4px 4px;
    border-radius: 3px;
    background-color: #1ec3a6!important;
    border: 1px solid #1ec3a6!important;
    font-weight:500;
    color:white;

    /* box-shadow: -1px 2px 8px 0 #e4dfdf; */
}

.headstuff{
  text-align:left;
  margin-top:3px;
  font-size:17px;
  color:black !important;
  cursor: pointer !important;
}

.headstuff1{
  text-align: left;
  margin-top: 3px;
  font-size: 17px;
  font-weight: 600;
  color: grey !important;
  font-family: 'Open Sans', sans-serif;
  }
@media only screen and (min-width:1600px){
  .banner-image{
    height: 55vh;
    width: 100% !important;
    margin-bottom: 20px;
   
  }
}



.banner-image{
  margin-top: -8px;
  height: 42vh;
  width: 100vw;
  margin-bottom: 20px;
 }


 .heading24 {
  font-family: 'lato',sans-serif;
  font-size: 16px;
  color: rgb(60, 57, 57);
  margin-top: -3px;
  margin-top: -5px;
}
  .heading98{
    font-family:'lato',sans-serif;
    font-size:14px;
    color: rgb(60, 57, 57);
    margin-top: 4px;
    
  }

  .heading99{
    font-family:'lato',sans-serif;
    font-size:14px;
    color: rgb(60, 57, 57);
    margin-top: -1px;
  }
  .heading90{
    font-family:'lato',sans-serif;
    font-size:14px;
    color:rgb(2, 31, 48);
   
  }
  .button26 {
    width: auto;
    height: 31px;
    margin-top: 6px !important;
    margin-left: 4px;
    background-color: #2aabd2 !important;
    border: 2px solid #2aabd2;
    padding: 13px 10px 0px 10px !important;
    text-align: center !important;
    display: inline-block;
    font-size: 43px;
    font-weight: 900;
    border-radius: 10px;
    color: #ffffff !important;
    font-family: 'Lato', sans-serif;
    transition: all 0.2s ease;
    box-shadow: -1px 1px 2px 0px #ffffff;
    cursor: auto;
}

  .button99{
    width: auto;
    height: 26px;
    margin-top: 4px;
    margin-left: 2px;
    background-color: white;
    border: 2px solid #2aabd2;
    padding: 8px 10px 0px 10px;
    text-align: center !important;
    display: inline-block;
    font-size: 10px;
    font-weight: 900;
    border-radius: 10px;
    color: #ffffff;
    font-family: 'Lato', sans-serif;
    transition: all 0.2s ease;
    box-shadow: -1px 1px 2px 0px #ffffff;
    cursor:auto;
  }

  .resourcecategory{
    height: 4vh;
    margin-left: -1vw;
    margin-top: -20px !important;
    padding: 1px 10px;
    font-size: 12px;
    color: white;
    /* width: 55vw; */
    font-family: 'Lato',sans-serif;
    background-color: #A52534;
    border-radius: 4px;
  }

  .categorypage{
    border: 2px solid #218ccc;
    font-weight: 600;
    text-transform: uppercase;
    padding: 1px 8px;
    display: inline-block;
    font-size: 10px;
    margin-top: 3px;
    background-color: white;
  }

  .categorypage1{
    margin-top:6vh !important;
    border: 2px solid #218ccc;
    font-weight: 600;
    text-transform: uppercase;
    padding: 1px 8px;
    display: inline-block;
    font-size: 10px;
    margin-top: 3px;
    background-color: white;
  }

  .categorypage:hover{
    color: #fff;
    background: #218ccc;
  }
  

.cat-button{
    font-size:14px;
    color:rgb(5, 34, 71);
    font-weight:700;
    padding-left: 20px;
    margin:-6px -5px 0px 10px;
}

.mang-button{
    width: 200px;
    height: 40px;
    background-color: rgb(236, 241, 241);
    border-radius: 10px;
}

.sidebarinside{
    overflow-y:scroll;
    height:600px;
      
}
 


.des{
    color: #353535 !important;
    font-size: 19px;
    font-family:'Source Sans Pro',sans-serif; 
    font-weight: 500 !important;
}

  .name{
    color: #353535 !important;
    font-weight: 100 !important;
    font-size: 14px !important;
    font-family:'Source Sans Pro',sans-serif; 
  }

  .author{
    color: #353535 !important;
    font-size: 18px;
    font-family:'Source Sans Pro',sans-serif; 
    font-weight: 600 !important;

  }
  .headstrip{
    background-color: #73cce7;
    padding:8px;
    text-align:center;
  }

  .head1 {
    margin-top: 6vw;
    margin-left:12px;
    color: #383838 !important;
    font-weight: 500 !important;
    font-size: 30px !important;
    font-family: 'montserrat', sans-serif;
  }
  .head2 {
    color:#f0f5f7;
    margin-left:12px;
    font-weight: 800 !important;
    font-size: 20px !important;
    font-family: 'montserrat', sans-serif;
  }
  .head3 {
    
    color: #353535 !important;
    font-weight: 100 !important;
    font-size: 15px !important;
    font-family:'montserrat',sans-serif; 
    margin-top: 0px;
  }
  .subheading {
    color: #383838 !important;
    margin-left:12px;
    font-weight: 500 !important;
    font-size: 20px !important;
    font-family: 'montserrat', sans-serif;
  }
  .content{
    color:rgb(65, 64, 64);
    font-size:14px;
    font-weight:400;
    padding-left:1px;
    text-align: justify;
    font-family: 'PT Sans', sans-serif;
  }
  .contentt{
    color:rgb(105, 105, 105);
    font-size:13px;
    font-weight:500;
    padding-top:3px;
    margin-left:1px;
    text-align: left;
    font-family: 'PT Sans', sans-serif;
  }
  .contenttpage{
    color:rgb(65, 64, 64);
    font-size:15px;
    font-weight:100;
    padding-top:3px;
    margin-left:1px;
    text-align: left;
    font-family:'Montserrat',sans-serif; 
  }

  .contentpage1{
    color:rgb(65, 64, 64);
    font-size:17px;
    font-weight:300;
    margin-left:0px;
    margin-top: -1vh;
    text-align: left;
    font-family:'Montserrat',sans-serif; 
  }


  .contentttpage{
    color:rgb(65, 64, 64);
    font-size:16px;
    font-weight:300;
    margin-left:11vw;
    margin-top:3vh;
    text-align: left;
    font-family:'Montserrat',sans-serif; 
  }

a{
    margin:none !important;
}

.sub-cat{
    font-size:15px !important; 
    font-weight: 500 !important;
    margin-top:6px !important;
    height: auto !important;
    font-family:'Montserrat',sans-serif; 
   
}

.cardstuffside {
    border-bottom: 1px solid #ece8e8;
    padding: 25px 10px;
    width:320px;
    margin:0px -20px;
  }
  
  

.filter1-card{
   
   
    color: rgb(61, 58, 58) !important;
    background-color: white;
    border-radius: 5px;
    /* height: 500px; */
    margin-right:20px; 
    height:74vh;
    box-shadow: 3px 4px 10px 0px #d9d8d8;
    padding:10px 0px 0px 10px;
    width:19vw;
    overflow-y: scroll;
    font-family:'Montserrat',sans-serif; 
}

@media only screen and (min-width:3840px){
  .filter1-card{
    margin-top: 4vh;
    color: rgb(61, 58, 58) !important;
    background-color: white;
    border-radius: 5px;
    margin-right:20px; 
    height:74vh;
    box-shadow: 3px 4px 10px 0px #d9d8d8;
    padding:10px 0px 0px 10px;
    width:19vw;
    overflow-y: scroll;
    font-family:'Montserrat',sans-serif; 
}
}
.filter2-card{
    font-size: 16px !important;
    font-weight: 400 !important;
    color: rgb(61, 58, 58) !important;
    background-color: white;
    border-radius: 5px;
    width: 21vw;
    margin-right:20px; 
    margin-left: 50px;
    height:74vh;
    box-shadow: 3px 4px 10px 0px #d9d8d8;
    overflow-y: scroll;    
}

@media only screen and (min-width:1920px){
  .filter2-card{
    font-size: 16px !important;
    font-weight: 400 !important;
    color: rgb(61, 58, 58) !important;
    background-color: white;
    border-radius: 5px;
    width: 21vw;
    margin-right:20px; 
    margin-left: 4vw;
    height:74vh;
    box-shadow: 3px 4px 10px 0px #d9d8d8;
    overflow-y: scroll;    
}
}

@media only screen and (min-width:3840px){
  .filter2-card{
    font-size: 16px !important;
    font-weight: 400 !important;
    color: rgb(61, 58, 58) !important;
    background-color: white;
    border-radius: 5px;
    width: 21vw;
    margin-top:4vh;
    margin-right:20px; 
    margin-left: 4vw;
    height:74vh;
    box-shadow: 3px 4px 10px 0px #d9d8d8;
    overflow-y: scroll;    
}
}
.filter3-card{
    font-size: 16px !important;
    font-weight: 400 !important;
    color: rgb(61, 58, 58) !important;
    background-color: white;
    border-radius: 5px;
    /* height: 500px; */
    margin-right:20px;
    margin-left: 34px; 
    width:  53vw;
    height:74vh;
    box-shadow: 3px 4px 10px 0px #d9d8d8;
    /* padding:30px 0px 0px 20px; */
}

@media only screen and (min-width:1920px){
  .filter3-card{
    font-size: 16px !important;
    font-weight: 400 !important;
    color: rgb(61, 58, 58) !important;
    background-color: white;
    border-radius: 5px;
    margin-right:20px;
    margin-left: 44px; 
    width:  53vw;
    height:74vh;
    box-shadow: 3px 4px 10px 0px #d9d8d8;
}
}

@media only screen and (min-width:3840px){
  .filter3-card{
    margin-top: 4vh;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: rgb(61, 58, 58) !important;
    background-color: white;
    border-radius: 5px;
    margin-right:20px;
    margin-left: 44px; 
    width:  53vw;
    height:74vh;
    box-shadow: 3px 4px 10px 0px #d9d8d8;
}
}

.section{
   
    border-radius: 2px;
    padding:20px 0px 0px 10px;
}

@media only screen and (min-width:3840px){
  .section{
    
    border-radius: 2px;
    padding:20px 0px 0px 10px;
} 
}
.main-navbar{
    margin-top:-10px;
}

.sec-navbar{
    /* border-top: 1px solid rgb(207, 207, 207) !important; */
    margin-top:-25px;
    height:50px !important;
}
.sec-navbar1{
    margin-top:-25px;
    height: 65px  !important;
    background-color: #bbebf3 !important 
}

.sec-navbar2{
  margin-top: -9px;
  height: 8vh !important;
  border-top:1px solid rgb(206, 198, 198);
  background-color: #38bdd4 !important;
  box-shadow:4px 3px 2px 0px #d8d8d8;
}

@media only screen and (min-width:1600px){
  .sec-navbar2{
    margin-top: -9px;
    height: 8vh !important;
    border-top:1px solid rgb(206, 198, 198);
    background-color: #38bdd4 !important;
    box-shadow:4px 3px 2px 0px #d8d8d8;
  }
}

@media only screen and (min-width:1920px){
  .sec-navbar2{
    margin-top: -9px;
    height: 5vh !important;
    border-top:1px solid rgb(206, 198, 198);
    background-color: #38bdd4 !important;
    box-shadow:4px 3px 2px 0px #d8d8d8;
  }
}
@media only screen and (min-width:2304px){
  .sec-navbar2{
    margin-top: -9px;
    height: 4vh !important;
    border-top:1px solid rgb(206, 198, 198);
    background-color: #38bdd4 !important;
    box-shadow:4px 3px 2px 0px #d8d8d8;
  }
  }

  @media only screen and (min-width:2560px){
    .sec-navbar2{
      margin-top: -9px;
      height: 53px !important;
      border-top:1px solid rgb(206, 198, 198);
      background-color: #38bdd4 !important;
      box-shadow:4px 3px 2px 0px #d8d8d8;
    }
  }
.copyright {
 
 
 
  
}

.adjust-cat{
  margin-top: 8px;
  color: white;
  font-size: 15px;
}

.sec-cat{
  font-family: 'Source Sans Pro',sans-serif;
  color: #515151 !important;
}
.sec-cat1{
  font-size:15px !important; 
  font-weight: 500 !important;
  /* margin-bottom:5px !important; */
  padding: 0px 0px 0px 8px !important;
  margin-top: -8px;
  font-family:'Source Sans Pro',sans-serif;
  color: #515151 !important;
}
.active{
  
  color:#484747!important;
}

.activenav{
  background-color: white !important;
  color:#404040 !important;
}

.activelaw{
  background: #f7f7f7 !important;
}

.activesec{
  background: #f7f7f7!important;
  border-bottom: 1px solid  #f7f7f7!important;
  margin-left: 8px;
  margin-top: 1vh;
  margin-bottom: -1vh;
  border-radius: 5px;
}

.activetab{
  background-color: #ece9e9!important;
  box-shadow: 3px 4px 10px 0px #d9d8d8;
}
.notactivetab{
  background-color: white;
  box-shadow: 3px 4px 10px 0px #d9d8d8;
}
.nav-pills.li{
  background-color: none!important;
}
.businus-logo{
    margin-top:-9px;
    height:40px;
}
.businus-logoshare{
  margin-top:4px;
  height:40px;
}
.descriptionstuff{
  padding:40px 0px 10px 0px;
  font-size:16px;
  font-weight:300;
  font-family:'Montserrat',sans-serif;
}
.detail2{
  background-color: rgb(255, 255, 255);
  width: 14vw;
  padding:0px 0px;
  border-radius:0px;
  text-align:left;
  margin-bottom:20px
}

.sidetag{
    font-family:'Open Sans ',sans-serif;
    font-weight:400; 
    font-size:14px !important;
    color: #212121;
    cursor: pointer;
}

.filters{
    height:300px !important;
}

.filters-cat{
    margin-bottom:20px;
    margin-left:16vw;
    border: 1px solid rgb(229, 229, 229);
    margin-top:25px;
    padding:30px 22px;
    width:230px;
    height:auto;
    min-height: 50vh;
    border-radius: 10px; 
    background-color: white;
  }

  @media only screen and (min-width:2304px){
    .filters-cat{
      margin-bottom:20px;
      margin-left:23vw;
      border: 1px solid rgb(229, 229, 229);
      margin-top:25px;
      padding:30px 22px;
      width:230px;
      height:auto;
      min-height: 50vh;
      border-radius: 10px; 
      background-color: white;
    }
  }

  @media only screen and (min-width:3840px){
    .filters-cat{
      margin-bottom:20px;
      margin-left:27vw;
      border: 1px solid rgb(229, 229, 229);
      margin-top:25px;
      padding:30px 22px;
      width:230px;
      height:auto;
      min-height: 50vh;
      border-radius: 10px; 
      background-color: white;
    }
  }
  .heading9 {
    color: #383838 !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    font-family: 'Roboto Condensed', sans-serif;
    text-align:center;
    
  }

  .cloud {
    display: inline;
    list-style-type: none;
    word-break: break-all;
    width:400px;
    padding: 10px 10px 50px 10px !important;
    margin-bottom:40px;
   
    
  }
  
  .button25 {
    margin-left: 70px;
    width: 120px;
    padding: 8px 18px 0;
    border-radius: 3px;
    background-color: #fff;
    border: 2px solid #2aabd2;
  }

  @media only screen and (min-width:1920px){
    .button25 {
      margin-left: 0px;
      margin-left: -7vh;
      width:120px;
      padding: 8px 18px 0px 18px;
      border-radius: 3px;
      background-color: white;
      border: 2px solid #2aabd2;
    } 
  }

  @media only screen and (min-width:2560px){
    .button25 {
      margin-left: 0px;
      margin-left: -15vh;
      width:120px;
      padding: 8px 18px 0px 18px;
      border-radius: 3px;
      background-color: white;
      border: 2px solid #2aabd2;
    }
  }

  @media only screen and (min-width:3840px){
    .button25 {
      margin-left: 0px;
      margin-left: -23vh;
      width:120px;
      padding: 8px 18px 0px 18px;
      border-radius: 3px;
      background-color: white;
      border: 2px solid #2aabd2;
    }
  }
.filter-buttons{
    border:1px solid rgb(210, 210, 210);
    width: 17vw;
    background-color: rgb(255, 255, 255);
    margin: -1vh 0 4vh;
    padding: 1vh 0 2vh 0;
    border-radius:5px;
    color:rgb(63, 63, 63);


}
.filter-buttons:active{
  border: 1px solid blueviolet;
}

.share-style{
  padding-left: 45%;
}

.search{
    width:160px;
    height: 37px;
    border:1px solid rgb(173, 173, 173);
    border-radius:5px;
}

.tab-text{
    color:rgb(75, 75, 75);
    font-size:14px;
    font-family:'Source Sans Pro',sans-serif;
    font-weight:500;
}
.tab-box{
    height:300px;
    overflow-y:scroll;
    margin-top:20px;
}

.form-inline .form-control {
    display: inline-block;
    width: auto;
    border: 1px solid #e7e7e7;
    vertical-align: middle;
    box-shadow: none;
}

.nav-pills>li.active>a, .nav-pills>li.active>a:focus, .nav-pills>li.active>a:hover{
  color: #fff;
  background-color: white ;
}

blockquote.twitter-tweet {
  display: inline-block;
  font-family: "Helvetica Neue", Roboto, "Segoe UI", Calibri, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  border-color: #eee #ddd #bbb;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  margin: 10px 5px;
  padding: 0 16px 16px 16px;
  max-width: 468px;
}

blockquote.twitter-tweet p {
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
}

blockquote.twitter-tweet a {
  color: inherit;
  font-weight: normal;
  text-decoration: none;
  outline: 0 none;
}

blockquote.twitter-tweet a:hover,
blockquote.twitter-tweet a:focus {
  text-decoration: underline;
}


.imagecredit {
  border: 1px solid #f3f0f0;
  padding: 30px 30px;
  height: 150px;
  margin: 30px;
  background: #fff;
}

 
  .author-image{
    height:100px;
    width:100px;
  }

  .doc-card{
    margin-left: -10px !important;
  }