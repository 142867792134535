.star{
    font-size: 28px !important;
    padding: 0;
}

.columnStyle{
    color: blue;
    font-size: 15px;
    width: 20%;
    padding-bottom: 10px;
}

.progress-rating{
    overflow: visible;
    margin-bottom: 5px;
    height: 7px;
    width: 88%;
    background-color: #f5f5f5;
    border-radius: 4px;
    box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
    margin-left: 7%;
}

.star-outer{
    position: relative;
    display: inline-block;
}

.star-inner{
    position: absolute;
    top:0;
    left: 0;
    white-space: nowrap;
    overflow: hidden;
    width: 0%;
}

.star-outer::before{
    content: "★★★★★";
    font-size: 28px;
    font-weight: 900;
    color: #cccccc;
}

.star-inner::before{
    content: "★★★★★";
    font-size: 28px;
    font-weight: 900;
    color: #0be4f8;
}

.review-star-outer{
    position: relative;
    display: inline-block;
}

.review-star-inner{
    position: absolute;
    top:0;
    left: 0;
    white-space: nowrap;
    overflow: hidden;
    width: 0%;
}

.review-star-outer::before{
    content: "★★★★★";
    font-size: 20px;
    font-weight: 900;
    color: #cccccc;
}

.review-star-inner::before{
    content: "★★★★★";
    font-size: 20px;
    font-weight: 900;
    color: #0be4f8;
}

.stars-inner{
    width: 50%;
}

.checked{
    color: orange;
}

.orange{
    color: orange;
}

.unchecked{
    color: white;
}

.group-client-review{
    background: #fff;
    padding: 2% 1%;
    border-bottom: 1px solid #ecebeb;
}

.client-review-title{
    color: #242323;
    font-size: 16px;
    font-weight: 600;
}

.client-review-c{
    font-size: 14px;
    padding-top: 1%;
}

.client-review-name{
    color: rgb(101 101 105);
    font-size: 14px;
    font-weight: 600;
}