.appointment-card{
    box-shadow: -3px 7px 9px 0px #efeded;
    margin: 20px 18px 5px -13px;
    padding: 7px 20px !important;
    background-color: #fff;
  
    height: 610px;
    overflow-y: scroll;
    width: 100%;
    position: absolute;
    border-radius: 10px;
    transition: .3s linear all;
}

.appointment-other-button{
padding: 6px;
margin: 3px 0px;
border-radius: 5px;
font-family: montserrat,'sans-serif';
color: #696868;
}

.appointment-other-box{
    padding: 2%;
    border: 2px solid #edebeb;
    position: relative;
    border-radius: 5px;
}

.appointment-other-chip{
    padding: 6px 12px;
    background-color: #e6e6e6;
    width: auto;
    margin-left: 1%;
}

.appointment-other-closeChip{
    padding: 6px 10px;
    cursor: pointer;
    background-color: #e6e6e6;
    width: auto;
    
}

.appointment-other-closeChip:hover{
    background-color: #f3a4a4;
}

.meeting-slot-days-c{
    list-style: none
}

.meeting-slot-days-c .meeting-slot-d{
    /* border-radius: 3px; */
    display: inline;
    font-family: montserrat,'sans-serif';
    /* padding: 12px 30px;
    margin-right: 4px;
    background-color: #dadce0;
    color: #3c4043;
    line-height: 34px;

    cursor: pointer; */
}

.meeting-booking-day{
    border: 1px solid #307AB2;
    background: #fff;
    margin-bottom: 2%;
    margin-right: 1%;
    font-size: 13px;
}

.meeting-day-active{
    background-color: #307AB2 !important;
    color: #fff !important;
}


/* .timing-slot-container{
    height: 0px;
    overflow: hidden;
    transition: 0.6s;
} */

.meeting-outer-time .meeting-inner-time{
    display: inline;
}

.meeting-timing{
    border: 1px solid #307AB2;
    background-color: white;
    margin-bottom: 2%;
    font-size: 14px;
    margin-right: 1%;
    font-family: montserrat,'sans-serif';
}

.meeting-timing-active{
    background-color: #307AB2 !important;
    color: white !important;
}

.appointment-breadCrumb1-row{
    padding: 4px 0px 4px 16px;
    width: auto;
    background-color: #1D5A81;
    border-radius: 2px;
    font-family: montserrat;
    color: white;
    font-size: 17px;
}
