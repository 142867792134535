
.calImages-div{
    background-color: white;
    overflow-y: scroll ;
    box-shadow:0px 1px 20px #f5f4f4;
    height:80vh;
    padding:20px;
    border-radius:10px;
    display:flex;
}

.cal-images{
    flex-direction:row;
    flex-wrap: wrap;
    height:200px;
    margin:40px;
    
}
.calImages-inputs{
    margin-left:40px;
}
.react-calendar{
    width: 100%;
    height: 100%;
    border: none;
}
.react-calendar__month-view__weekdays{
    margin-bottom: 2vh;
    color: #263238;
}
.react-calendar__month-view__weekdays abbr{
    font-weight: 500;
    font-size: 21px;
    text-decoration: none;
}

.react-calendar__month-view__days button{
    font-size: 20px;
    /* border-radius: 50px; */
    color: #263238;
}

.react-calendar__viewContainer{
    /* background-color: #eee; */
    border-radius: 10px;
}
.react-calendar__month-view__days{
     background-color: #eee;
     border-radius: 10px;
}

.react-calendar__tile{
    line-height: 23px;
}
.react-calendar__navigation button{
    font-size: 25px;
    color: #263238;
}
.react-calendar__navigation{
    margin-bottom: 5vh;
}
.react-calendar__tile--active{
    color: white;
}
.react-calendar__tile--now{
    /* background-color: red; */
    background-color: #eee;
}

.highlight {
    color:red;
    background-color: rgb(0, 255, 85);
 }

 .react-calendar button:enabled:hover {
    cursor: pointer;
    background-color: #006edc;
}

@media(max-width:500px){
    .react-calendar__navigation button{
        font-size: 20px;
    }  
    .react-calendar__month-view__weekdays abbr{
        font-size: 18px;
    }
    .react-calendar__month-view__days button{
        font-size: 16px;
    }
}
 