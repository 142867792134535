
.tags{
    background-color: rgb(0, 145, 255);
    color:white;
    border:1px solid white;
    border-radius:5px;
    font-size:15px;
    margin:10px 5px 0px 5px;
}

.dropdown .dropdown-menu {
    
    transition: all 600ms ease;
    -moz-transition: all 200ms ease;
    -webkit-transition: all 0.0010ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
    -o-transition: all 0.001ms ease;
    -ms-transition: all 2000ms ease;
  }
  .multitab-row{
      margin-top:30px;
      margin-left:60px;
      margin-bottom:60px;
  }
  .dropdown-content {
     
     width:60px;
    padding:5px 0px ;
    display: none;
    border: none;
    box-shadow: 0px 0px 11px 1px #bbbbbb;
  }
  
  .dropdown:hover .dropdown-menu {
    width:50px;
    display: block;
    margin-top:-10px;
  }
  .dropdown-item {
    padding: 5px 2px !important;
    list-style:none;
    font-family:raleway,'sans-serif';
    color:rgb(97, 97, 97);
  }
  
  .dropdown-item:hover {
      text-decoration:none;
    color: rgb(2, 56, 87) !important;
   font-weight:400;
    list-style:none;
    font-family:raleway,'sans-serif';
  }

.subText{
    font-size:15px;
    margin-left:15px;
    
}.sidebar {
    background-color: #ffffff;
    height: calc(100vh - 90px);
    margin-top: 0px;
    padding: 10px 0px;
    width: 100%;
    box-shadow: 0px 1px 9px 0px #f7f5f5;
}
#sidebar1{
    display:block;
}
.sidebar-elementhead {
    font-size: 15px !important;
    font-weight: 700;
    padding: 0px 5px 5px 13px;
    font-family: Montserrat,'sans-serif';
    color: #696868 !important;
    text-align: left;
 
}

.sidebar-head{
    font-size:15px !important;
    font-weight:700;
    padding:10px;
    
    font-family:Montserrat,'sans-serif';
    color:#23527c  !important;
    text-align:left;
    padding-left:15px;
    

}

a{
    color: inherit;
}


.detail-fields {
    font-family: Montserrat,'sans-serif';
    font-weight: 600;
    font-size: 14px !important;
    margin-top: 0px;
    color: rgb(56, 56, 56);
    background-color: #ffffff30;
    /* border: 1px solid; */
    border: 1px solid #e5efef;
    padding: 8px;
    text-align: center;
    white-space: initial;
    word-break: break-word;
}

.sidebar-element {
    margin: 11px 0px 6px 0px;
    padding: 8px 1px;
    font-size: 1.4rem;
    text-align: left;
    border-bottom: 1px solid #fbf6f6;
    font-family: Montserrat,'sans-serif';
    color: #696868;
    font-weight: 600;
}
.sidebar-element:hover {
  
    cursor:pointer;
}
.sidebar-subelement{
    
    padding:2px 10px 2px 9px;
    font-size:1.3rem;
    text-align:left;
    font-family:Montserrat,'sans-serif';
    color:rgb(99 97 97);
  font-weight:500;
  display:none;
  
  

}
.sidebar-subelement:hover{
    
   cursor:pointer;


}

.sub-tabs{
    padding:10px;
    background-color:rgb(250, 250, 250);
    
    border-radius:4px;
}

.sub-tabs:hover{
    padding:10px;
    background-color:rgb(255, 255, 255);
    
    border-radius:4px;
}

.sidebar-sectionName{
    opacity:0.8;
    font-size:18px;
    padding:8px;
    text-align:left;
    font-weight:700;
    color:grey;
    font-family:Montserrat,'sans-serif';
}

.blogImageArray{
    width: 100px;
    height: 80px;
    margin-right: 1%;
    margin-bottom: 3%;
    cursor: pointer;
}

.modal-close{
    float: right;
    font-size: 20px;
    font-weight: 600;
    padding: 2px 10px;
    background: #d8d8d8;
    cursor: pointer;
}

.reminder-options{
    text-align:center;
    padding:8px 0px;
    color:rgb(46, 46, 46);
    border: 1px solid rgb(239 234 234);
    font-size:16px;
    font-family:montserrat,'sans-serif';
    font-family:600,

}

.oneTab{
    margin:5px 0px;
}
.fa{
    
    padding:0px 6px;
   
}
.fa{
    margin:0px 0px;
}

.workflow-table{
background-color: #f3f3f330 !important;

}
.right{
    text-align:right;
}

.navbar-right{
    margin-right:5px;
}
.navbar-brand{
    padding:10px 10px 0px 10px;
    margin-top:-5px;
    height:57px;
}
.subtext{
    color:#307AB2;
    font-size:17px;
    font-weight:500;
    font-family:montserrat,'sans-serif';
    margin-top:-5px;
    margin-left:8px;
}
.navbar {
    background-color: #ffffff;
    height: 90px;
    /* box-shadow: 0px -5px 10px #fdfdfd; */
    z-index: 9999;
    width: auto;
    box-shadow: 0px 1px 9px 0px #f7f5f56b;
    margin-bottom: 0px !important;
    /* border-bottom: 1px solid rgb(241, 241, 241); */
}
.navbar-nav>li>a {
    padding-top: 26px;
    padding-bottom: 22px;
    margin-right:25px;
}

.mainDiv{
    
    background-color:rgb(253 253 253);
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 90px);
}

.mainDiv_blg{   
    background-color:rgb(253 253 253);
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 90px);
}

.tabImage{
    width:140px;
}
.tab-row{
    margin-top:-20px;
}

.tab-text {
    color: rgb(75, 75, 75);
    font-size: 14px;
    font-family: 'Montserrat',sans-serif;
    font-weight: 500;
}

/*form - css*/

.form-text{
    
    font-size:13px !important;
    font-family: Montserrat,'sans-serif';
    font-weight:500;
    
}


.table>tbody>tr>td, .table>tfoot>tr>td {
    padding: 4px 4px 4px 11px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid rgb(236, 236, 236);
    width:200px;
}
.form-textHead{
    font-size:13px;
    font-family:Montserrat,'sans-serif';
    font-weight:600;
    text-align:left !important;
   color:rgb(44, 43, 43);
   text-transform:uppercase;
   
   
}

#border-none{
    border:none !important;
  }








.cell{
    padding:10px 10px;
    border-top:1px solid rgb(235, 235, 235);
    
    width:500px !important; 
    background-color:rgb(255, 255, 255);
    text-align: center;
}
.cell-head{
    padding:2px 10px;
   
    
    width:auto; 
    background-color:rgb(255, 255, 255);

}
.busi-row{
    padding:10px;
    border-top:1px solid rgb(230, 230, 230);
}
.CardBody{
    border:1px solid rgb(236, 236, 236);
    
  }

    .red{
        background-color: red;
        
    }
    .link{
        padding-top:20px;
        color:blue;
        }
    
    .link:hover{
    color:rgb(5, 5, 126);
    }

    a { color: inherit; } 

    a:hover {
        color: #ffffff;
        text-decoration: none;
    }

    a:active {
        color: #ffffff !important;
        text-decoration: none;
    }
    .header-div{
      
       
    padding:10px 30px;
background-color: white;
z-index:5000;}

.active1{
   border-top: 2px solid rgb(73, 133, 189) !important;
   border-left: 2px solid rgb(73, 133, 189) !important;
   border-right: 2px solid rgb(73, 133, 189) !important;
    font-weight:600px;
}
.active5{
    border-top: 2px solid #53BA88 !important;
    border-left: 2px solid #53BA88 !important;
    border-right: 2px solid #53BA88 !important;
     font-weight:600px;
 }
 .active3{
    border-top: 2px solid #ED863A !important;
    border-left: 2px solid #ED863A !important;
    border-right: 2px solid #ED863A !important;
     font-weight:600px;
 }
 .active6{
    border-top: 2px solid #53BA88 !important;
    border-left: 2px solid #53BA88 !important;
    border-right: 2px solid #53BA88 !important;
     font-weight:600px;
 }
 .active4{
    border-top: 2px solid rgb(255, 208, 0) !important;
    border-left: 2px solid rgb(255, 208, 0) !important;
    border-right: 2px solid rgb(255, 208, 0) !important;
     font-weight:600px;
 }

 .form-control select{
     margin-bottom:10px !important;
 }

.sidebar-subelement2{
    display:none;
    margin-left:30px;
}
a {
     background-color: none !important; 
}

.header-div2{
      
   
padding:10px 30px;
background-color: white;
z-index:5000;}

.superoptions{
    border-top:2px solid rgb(197, 197, 197);
    border-left:2px solid rgb(197, 197, 197);
    border-right:2px solid rgb(197, 197, 197);
    text-align:center;
    padding:7px 0px;
    
    margin-left:15px;
    font-size:15px;
    font-family:montserrat,'sans-serif'
}

.superoptions:hover{
    cursor:pointer
}


.downdoc{
    padding:1px 0px;
}
.header-divi{
    height: 204px;
    padding: 10px 0px;
    background-color: #ffffff;
    
    overflow:auto;
}

.header-divi1{
    height:120px;
  
padding:10px 30px;
background-color: white;}

.head-name
{font-weight:500;
    font-size:27px;
    text-align:left;
    padding-left:0px;
    font-family:montserrat,'sans-serif';
}

.head-mail{
    font-weight:400;
    font-size:14px;
    text-align:left;
    font-family:montserrat,'sans-serif';
}
.head-message{
    font-weight:500;
    font-size:25px;
    text-align:left;
    padding-left:14px;
    font-family:montserrat,'sans-serif';
}
.head-mail-important{
    font-weight:600;
    font-size:16px;
    text-align:left;
    font-family:raleway,'sans-serif';
}

.head-button{
    margin-top:20px;
    font-weight:400;
    font-size:14px;
    text-align:left;
}

.dedicated-page{
    margin-right: 17px;
    overflow-x: hidden;
    height: 78vh;
    border-radius:5px;
    margin-left: -3px;
    padding-bottom: 50px;
    background-color: white;
    box-shadow: 0px 1px 11px 0px #f5f5f5 !important;
}

.dedicated1-page{
    margin-right:36px;
    overflow-x:hidden;
    height: 67vh;
    margin-left:-30px;
    border:1px solid rgb(233, 233, 233);
}
#section{
    
    overflow-y: scroll;
    height:700px;
    border:1px solid rgb(235, 235, 235);
    margin-right:20px;
}
.content-div{
    padding:2px 60px;
    
}

.content1-div{
    background-color: white;
    padding:30px 60px 20px 60px;
    
}
.sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
  }

.Div{
   
    margin-right:40px;
    margin-left:12px;
}
.DashboardDiv{
    font-size:20px;
     font-weight:900;
     padding:10px 35px 0px 7px;
     font-family:montserrat;
     background-color:rgba(255, 255, 255, 0);
     height:75vh;
    
    margin-right:10px;
    margin-left:-15px;
}

.dashboard-head{
    font-Size:24px;
    font-Weight:900;
    padding-Bottom:15px; 
    padding-left: 10px;
    font-Family:montserrat;
}
.dashboard-subhead{
    font-Size:16px;
     font-Weight:600;
     padding-Bottom:15px;
     font-Family:montserrat;
}


.list-head{
    color:white;
    font-family:raleway,'sans-serif';
    font-size:18px;
    font-weight:600;
    text-align:left;
    margin-bottom:40px;
    border-radius:4px;
    border-bottom: 1px solid #eeeeee;
    padding:10px 10px;
    margin-left:-15px;
    background-color:#1E5B81;
}
.section{
    margin-left:0px;
   
}



.sidebar-link {
    color: #696868 !important;
    text-decoration: none !important;
    padding-bottom: 5px;
    padding-top: 5px;
    font-size: 14px;
    margin-left: 4px;
    margin-right: 10px;
}
Link{
    cursor:pointer;
}

.sidebar-link:hover{
    color: #52BA9B  !important;
    
    font-weight:600;
}

.tablebusi{
    width:auto;
}




li{
    text-decoration:none !important;
}

.tab {
    border-radius: 8px;
    margin: 10px 0px;
    padding: 5px;
    width: 182px;
    height: 143px;
    background-color: rgb(255 255 255);
    box-shadow: 7px 7px 16px 0px #efefef;
}
.tab:hover{
    
    border-radius:10px;
    margin:10px 0px;
    padding:5px;
    background-color: rgb(255, 255, 255);
    box-shadow: none;
}

.tab1 {
    border-radius: 8px;
    margin: 10px 0px;
    padding: 5px;
    width: 199px;
    height: 143px;
    background-color: rgb(255 255 255);
    box-shadow: 7px 7px 16px 0px #efefef;
}
.tab1:hover{
    
    border-radius:10px;
    margin:10px 0px;
    padding:5px;
    background-color: rgb(255, 255, 255);
    box-shadow: 3px 2px 8px 0px #bdd1cf
}
.tab2 {
    border-radius: 8px;
    margin: 10px 0px;
    padding: 5px;
    width: 199px;
    height: 143px;
    background-color: rgb(255 255 255);
    box-shadow: 7px 7px 16px 0px #efefef;
}
.tab2:hover{
    
    border-radius:10px;
    margin:10px 0px;
    padding:5px;
    background-color: rgb(255, 255, 255);
    box-shadow: 3px 2px 8px 0px #bdd1cf
}
.tab3 {
    border-radius: 8px;
    margin: 10px 0px;
    padding: 5px;
    width: 199px;
    height: 143px;
    background-color: rgb(255 255 255);
    box-shadow: 7px 7px 16px 0px #efefef;
}
.tab3:hover{
    
    border-radius:10px;
    margin:10px 0px;
    padding:5px;
    background-color: rgb(255, 255, 255);
    box-shadow: 3px 2px 8px 0px #bdd1cf
}
.tab-row{
    padding:10px;
    border-top:5px solid rgb(248, 248, 248);
    border-bottom:5px solid rgb(248, 248, 248);
    margin-top:10px;
}
.tab-row1{
    padding:10px;
  
    margin-top:10px;
}
.tab-row-head{
font-size:18px;
font-weight:500;
color:rgb(68, 68, 68);

padding-bottom:20px;
}

.Account-Card {
    margin: -1px -15px;
    padding: 20px;
    background-color: white;
    box-shadow: -1px 1px 16px #f4f4f4;
    border-radius: 10px;
    height: 500px;
}

.eye-button{
    background-color: white !important;
    border:2px solid  #52ba9b4b !important;
    border-radius:4px;
    margin-left:16px;
}
.account-head {
    color: rgb(53, 53, 53);
    font-family: raleway,'sans-serif';
    font-weight: 400;
    margin-top: 15px;
    font-size: 17px !important;
    font-family: montserrat,'sans-serif';
    margin-bottom: 10px;
    margin-left: -1px;
    background-color: #E5EEEF;
    padding: 9px 14px;
    font-weight: 600;
}

.account-head1 {
    color: rgb(53, 53, 53);
    font-family: raleway,'sans-serif';
    font-weight: 400;
    margin-top: 0px;
    font-size: 20px !important;
    font-family: montserrat,'sans-serif';
    margin-bottom: -3px;
    /* margin-left: 30px; */
    background-color: #d0e9e1;
    padding: 6px 46px;
    font-weight: 600;
}

.workflow-input{
    width: 100%;
    border-radius: 9px;
    height: 38px;
    border: 1px solid #f3f2f2;
    padding: 5px !important; 
    
}

.workflow-input1{
    background-color:#ffffff !important;
    color: #444444 !important;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 5px;
    border: 1px solid rgb(243 240 240) !important;
    width: 99% !important;
    transition: all 0.5s ease-in-out;
    border-radius: 5px 5px 5px 5px;
}

.account-info{
    color:rgb(22, 31, 36);
    font-family: raleway,'sans-serif';
    
    font-weight:500;
    font-size:17px;
    padding:10px 0px;
 }
 .account-col1{
  
    padding-top:60px;
  
 }
 .account-col2{
   
    margin-top:0px;
  
 }


 input[type=text], input[type=password], input[type=number] {
    background-color:#ffffff ;
    color: #444444 !important;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 6px 0px;
    border: 1px solid rgb(243 240 240) !important;
    width: 99% !important;
    transition: all 0.5s ease-in-out;
    border-radius: 5px 5px 5px 5px;
}
 

 .nav-item{
     margin-right:-20px;
    font-size:15px;
    font-family:raleway,'sans-serif';
    color:rgb(26, 26, 26) !important;
}
.nav-item1{
    margin-right:10px;
   font-size:14px;
   font-family:raleway,'sans-serif';
   color:rgb(26, 26, 26) !important;
}
.nav-item:hover{
    color:black;
    
}

.nav-item:active{
    color:black;
   
}

.tableDiv {
    height: 70vh;
    overflow: auto;
    display: block;
}

.myTable{
    height: 74vh;
}
.addWorkFlowTable{
    width:1500px;
}



.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td{

    border: none !important;
}

.ReactTable .rt-thead.-header{
    box-shadow: 0 0px 0px 0 rgba(0,0,0,0.15) !important;
}

.ReactTable{
    position: relative;
    display: flex;
    flex-direction: column;
    padding:10px 10px;
    background-color: white;
    border:none !important;
    box-shadow: 0px 1px 9px 0px #f7f5f5;
}
.ReactTable .rt-tr-group {
    flex: none !important;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.ReactTable .rt-th, .ReactTable .rt-td {
    flex: 1 0;
    white-space: nowrap;
    font-family: montserrat !important;
    font-size: 13px !important;
    font-weight: 500;
    text-overflow: ellipsis;
    padding: 7px 5px;
    margin-top:2px;
    overflow: hidden;
    transition: .3s ease;
    transition-property: width,min-width,padding,opacity;
}
.fieldbox{
    box-shadow:none;
}
/* input[type=text], input[type=password] {
    background-color:#ffffff ;
    color: #444444 !important;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 5px;
    border: 1px solid rgb(243 240 240) !important;
    width: 83%;
    transition: all 0.5s ease-in-out;
    border-radius: 5px 5px 5px 5px;
} */
.ql-editor {
    box-sizing: border-box;
    line-height: 1.42;
    height: 100%;
    outline: none;
    overflow-y: auto;
    padding: 12px 15px;
    tab-size: 4;
    border: 1px solid rgb(243 240 240) !important;
    background-color:#ffffff;
    -moz-tab-size: 4;
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;
}


.content1-div > input[type=text], input[type=password] {
        background-color: #f9f9f9 !important;
        color: #444444 !important;
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 18px;
        margin: 5px;
        border:1px solid rgb(209, 209, 209);
        width: 83%;
        transition: all 0.5s ease-in-out;
        border-radius: 5px 5px 5px 5px;
    }

    textarea {
        background-color: #ffffff !important;
        color: #444444 !important;
        padding: 7px 10px;
        height: 122px;
        border: 1px solid rgb(243 240 240) !important;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 13px;
        margin: 4px 12px 0px 5px;
        /* margin: 1px 0px 0px 0px; */
       
        width: 85%;
        transition: all 0.5s ease-in-out;
        border-radius: 5px 5px 5px 5px;
    }
    input, button, select, textarea {
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
    }

    .mainScreenKhub{
        background-color:#ffffff;
        height:83vh;
        overflow-y:scroll;
    }

    .khub-submit-button{
        background-color:#307AB2 !important
    }
    .mainScreen{
        background-color:#ffffff;
        height:100vh;
    }
    .button1 {
        margin: 49px 30px;
        background-color: #3bc28a!important;
        border: none;
        font-family: montserrat,'sans-serif';
        color: rgb(255 255 255) !important;
        padding: 10px 21px !important;
        text-align: center;
        font-weight: 500;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        border-radius: 6px;
        cursor: pointer;
        box-shadow: 0px 1px 11px 0px #dbdbdbd6;
    }
 

    .ql-toolbar.ql-snow {
        border: 1px solid #f3f3f3 !important;
        box-sizing: border-box;
        font-family: 'Montserrat', sans-serif;
        padding: 8px;
        border-radius:4px;
        background-color: white;;
    }

    .ql-container.ql-snow {
        border: 1px solid #f7f7f7 !important;
        border-radius:4px;
        background-color: white;
    }

   .ql-container{
       height:60% !important;
   }
    .sidebar-subelement > div {
        margin: 10px -12px;
    }

    .header-divi-mail {
        height: 100px;
        padding: 10px 13px;
        background-color: #ffffff;
        
    }

.buttonRed{
  
    background-color: rgb(255, 87, 9);
    border: none;
    font-family:montserrat,'sans-serif';
    color: rgb(255, 255, 255);
   border-radius:5px;
   font-family:montserrat,'sans-serif';
   padding:5px 20px;
   margin:0px 30px 
   
}
.buttonYellow{
  
    background-color: rgb(7, 164, 255);
    border: none;
    font-family:montserrat,'sans-serif';
    color: rgb(255, 255, 255);
   border-radius:5px;
   font-family:montserrat,'sans-serif';
   padding:5px 20px;
   margin:0px 30px 
   
}


/* FOR DASHBOARD CARDS */
.card-counter {
    box-shadow: -3px 7px 9px 0px #efeded;
    margin: 20px 18px 5px -13px;
    padding: 7px 20px !important;
    background-color: #fff;
    height: 154px;
    /* width: 263px; */
    border-radius: 10px;
    transition: .3s linear all;
}


  .card-counter:hover{
    box-shadow: 4px 4px 20px #DADADA;
    transition: .3s linear all;
  }

  .card-counter.primary{
    background-color: #ffffff;
    color: #FFF;
  }

  .card-counter.primary1{
    background-color: rgb(8, 196, 243);
    color: #FFF;
  }
  .card-counter.danger{
    background-color: #ef5350;
    color: #FFF;
  }  

  .card-counter.success{
    background-color: #66bb6a;
    color: #FFF;
  }  

  .card-counter.info{
    background-color: #26c6da;
    color: #FFF;
  }  

  .card-counter i{
    font-size: 5em;
    opacity: 0.5;
  }

  .card-counter .count-numbers{
    position: absolute;
    right: 55px;
    top: 35px;
    font-size: 46px;
    display: block;
    color:#52ba9b;
    font-weight:600;
  }

  .card-counter .count-name{
    position: absolute;
    right: 55px;
    top: 100px;
   color:#767575;
    text-transform: capitalize;
    opacity: 1;
    display: block;
    font-size: 18px;
    font-weight:600;
  }
  .rt-td {
    border-top:1px solid rgb(248, 248, 248) !important;
  
    border-left:1px solid rgb(248, 248, 248) !important;
    border-right: 1px solid rgb(248, 248, 248) !important;
    height:50px;
    
}

.ReactTable .-pagination {
    z-index: 1;
    display: flex;
    height:4vh;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    padding: 0px;
    font-size: 12px;
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.1);
    border-top: 2px solid rgba(0,0,0,0.1);
}
  .fa-ticket{
      color:white;
  }
  /*.fa-plus 
  {
      font-size:90px !important ;
  }*/
  /* END FOR DASHBOARD CARDS */

  /*TIMELINE*/

  .timeline {
    list-style-type: none;
    position: relative;
    z-index: 0;
}
.timeline:before {
    content: ' ';
    background: white!important;;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 0;
}
.timeline:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 29px !important;
    width: 2px;
    margin-left: 0% !important; 
    content: '';
    background-color: #c9fbec  !important;
    height: auto;
   /* z-index:-1;*/
}
.timeline > li {
    margin: 20px 0;
    padding-left: 20px;
   /* z-index: 0;*/
}

/*timeline-user-comm*/
.timeline-user-comm {
    list-style-type: none;
    position: relative;
    z-index: 0;
}
.timeline-user-comm:before {
    content: ' ';
    background: white!important;;
    display: inline-block;
    position: absolute;
    left: 10px;
    width: 10px;
    height: 100%;
    z-index: 0;
}
.timeline-user-comm:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 29px !important;
    width: 2px;
    margin-left: 0% !important; 
    content: '';
    background-color: #c9fbec  !important;
    height: auto;
   /* z-index:-1;*/
}
.timeline-user-comm > li {
    margin: 20px 0;
    padding-left: 20px;
   /* z-index: 0;*/
}

  /*  .timeline > li:before {
        content: ' ';
        background:  #22ac82 ;
        display: inline-block;
        position: absolute;
        border-radius: 50%;
        border: 3px
         solid #0f468d;
            left: 45%;
            top: 13px;
            width: 113px;
            z-index:50px
            height: 109px;
            /* z-index: 10000;*/


.update {
    list-style-type: none;
    position: relative;
    z-index: 0;
}
.update:before {
    content: ' ';
    background: #22c0e8;
    display: inline-block;
    position: absolute;
    left: 34px;
    width: 2px;
    height: 100%;
    z-index: 0;
}
.update > li {
    margin: 20px 0;
    padding-left: 20px;
    z-index: 0;
}
.update > li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #22c0e8;
    left: 20px;
    width: 30px;
    height: 30px;
    z-index: 0;
}

.proposals{
    list-style-type:none !important;
}

.float-right{
    float: right;
}

.float-left{
    float: left;
}

.attachment{
    margin:2px 0px 0px 20px !important;
    padding-top:4px 10px !important;
    background-color:rgb(255, 255, 255) !important;
    border:2px solid #52ba9b7a !important;
    border-radius:4px !important;
    font-size:10px;
    color:rgb(0, 0, 0);
}
.viewattachment{
    margin:7px 0px 0px 0px !important;
    padding-top:10px;
    background-color:rgb(48, 168, 248) !important;
    border:none !important;
    border-radius:0px !important;
    font-size:10px;
    color:rgb(0, 0, 0);
}
#myFileInput {
    display:none;
}


.mail-head{
    margin-left:10px;
font-size:14px;
font-weight:500;

font-family:'montserrat', sans-serif;
}

.mail-subhead{
    font-size:14px;
    font-weight:400;
font-family:'montserrat', sans-serif;
}





.send-button{
    background-color: rgb(14 186 154) !important; 
    width:150px;
    border:none;
    font-size:14px;
    margin-top:20px;
}


.fieldbox1{
    width:300px;
    height:30px;
}

.montserrat{
    font-family:montserrat,'sans-serif'
}

.timeline-user-comm-head{
    word-break:break-word !important;
    font-size:18px;
    font-weight:600;
}

.timeline-user-comm-subhead{
    font-size:14px;
    font-weight:400;
    font-family: montserrat;
}

.timeline-user-comm-card{
    
    padding:5px 20px;
    border-radius: 5px;
    width:800px;
    margin-left:30px;
}

.update-card{
    
    padding:5px 20px 30px 40px;
   
    
    width:100%;
}

.mail-card{
    border-left:1px solid  rgb(231, 231, 231);
    border-right:1px solid  rgb(233, 233, 233);
    border-bottom:1px solid  rgb(245, 245, 245);
    padding:10px 20px 10px 10px;
   
    background-color: white;
    width:100%;
}

.timeline-card > a:hover{
    color:black !important;
}

.timeline-elements:hover{
    color:black;
}
.timeline-elements{
    display:box;
}
.timeline-user-comm-elements{
    display:box;
}
.timeline-user-comm > li {
    position: relative;
    min-height: 50px;
    margin-bottom: 10px !important; 
}

.timeline-user-comm-card{
    padding:10px !important;
}
.mail-grid-button{
    background-color: #1D5A81;
    width:60px;
}

.notify-button{
    background-color: red;
    border-radius:50px;
   height:25px;
   margin-left:-15px;
   width:25px;
   font-size:13px;
   padding:0px;
    margin-bottom:30px;
    border:2px solid rgb(240, 125, 125);
}

.notify{
    padding-left:50px;
}

.bell-icon{
    height:25px;
}


.options2{
    width:146px;
    text-align:center;
    padding:8px 30px 8px 10px;
    margin-left:0px;
    
    font-size:14px;
   
    font-family:montserrat,'sans-serif'
}

.options5{
    width:135px;
    text-align:center;
    padding:8px 30px 8px 10px;
    margin-left:0px;
    
    font-size:14px;
   
    font-family:montserrat,'sans-serif'
}

.options8{
   
    text-align:center;
    padding:8px 10px 8px 10px;
    margin-left:0px;
    
    font-size:14px;
   
    font-family:montserrat,'sans-serif'
}

.options8{
   
  cursor:pointer
}

.options6{
    width:185px;
    text-align:center;
    padding:8px 30px 8px 10px;
    margin-left:30px;
    
    font-size:14px;
   
    font-family:montserrat,'sans-serif'
}

.options7{
   
    text-align:center;
    padding:8px 0px 8px 10px;
    margin-left:0px;
    
    font-size:14px;
   
    font-family:montserrat,'sans-serif'
}
.options4{
    width:200px;
    text-align:center;
    padding:8px 30px 8px 10px;
    margin-left:0px;
    
    font-size:14px;
   
    font-family:montserrat,'sans-serif'
}

.active2{
    color:rgb(33, 108, 194) !important;
     font-weight:600 !important;
 }


.options2:hover{
    cursor:pointer
}
.options3:hover{
    cursor:pointer
}
.options5:hover{
    cursor:pointer;
}
.options6:hover{
    cursor:pointer;
}
.options3 {
    width: 211px;
    text-align: center;
    padding: 8px 30px 8px 17px;
    margin-left: 21px;
    font-size: 14px;
    font-family: montserrat,'sans-serif';
}
.payStatus{
   background-color:#1D5981;
    border:none;
    font-size:15px;
    padding:10px;
}

.processStatus{
    background-color:#1D5981;
    border:none; 
    font-size:15px;
    padding:10px;
}

.head-button1{
    margin-top:20px;
    border-radius:6px;
    padding:4px;
    color:white;
    font-weight:600;
    
    border:none;
   
}

.head-button2{
    margin-top:6px;
    border-radius:6px;
    font-weight:600;
    padding:6px;
    color:white;
    
    border:none;
    
    
}

.multidiv{
    margin-top:40px;
}

.options1{
    text-align:center;
    padding:7px 0px;
    margin:0px 20px;
    color:white;
    font-size:16px;
    font-family:montserrat,'sans-serif'
}
.options1:hover{
cursor:pointer;
}
.form-col{
    margin:10px 40px;
}

.document-card{
    margin:40px 30px;
    padding:20px;
    background-color:rgb(255, 255, 255);
    font-weight:700px;
    height:120px;
    font-size:17px;
    border-radius:10px;
    border:1px solid rgb(223, 223, 223);
}



.header {
    background-color: rgb(255, 255, 255);
    font-family: montserrat,'sans-serif';
    padding: 11px 20px;
    font-size: 22px;
    color: #fbfbfb;
    z-index: -5;
}
.card{
    background-color: white;
}

.alert-nouser{
    background-color:rgb(255, 255, 255);
    font-family: montserrat,'sans-serif';
    padding:20px 70px;
    font-size:32px;
    font-weight:700;
    color: rgb(192, 52, 52);
    border-bottom:1px solid rgb(224, 224, 224);
    z-index:-5;
}

.tab-main {
    color: #363535;
    border-radius: 21px;
    margin: 30px 0px;
    padding: 5px;
    width: 230px;
    height: 180px;
    background-color: #ffffff;
    box-shadow: 7px 7px 20px 0px #dfdfdfbf;
}

.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    margin:3px 6px;
    line-height: 1.42857143;
    color: rgb(119, 119, 119);
    background-color:#ffffff;
    background-image: none;
    border: 1px solid rgb(243 240 240) !important;
    border-radius: 4px;
    font-family:montserrat,'sans-serif';
    box-shadow:none !important;
   
}

.form-control-extension{
    margin: 16px 4px;
    width: 95%;
}

.css-yk16xz-control {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #ffffff !important;
    border: 1px solid rgb(243 240 240) !important;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
}
.tab-image{
    color:rgb(253, 113, 113) !important;
    height:50px;
    margin-top:20px;
    font-size:50px;
}
.tab-image0{
    color:rgb(253, 113, 113) !important;
    height:50px;
    margin-top:20px;
    font-size:50px;
}

.tab-image1{
    color:rgb(106, 183, 255) !important;
    height:50px;
    margin-top:20px;
    font-size:50px;
}

.tab-image2{
    color:rgb(103, 255, 141) !important;
    height:40px;
    margin-top:20px;
    font-size:50px;
}

.tab-image3{
    color:rgb(243, 208, 54) !important;
    height:40px;
    margin-top:20px;
    font-size:50px;
}



.addUser{
    border:none !important;
    padding:4px 16px !important;
    color:rgb(255, 255, 255) !important;
    border-radius:3px;
    background-color:rgb(252, 175, 11) !important ;
   
    border:none;
    font-weight: 700;
    margin-top:4px;
}

.regUser{
    
    padding:4px 10px !important;
    color:rgb(247, 247, 247) !important;
    border-radius:3px !important;
    background-color:#6b7ff0 !important;
    
    border:none;
    font-weight: 600;
    margin-top:4px;
    
}


.tab-text-main{
    font-family:Montserrat,'sans-serif';
    font-weight:600;
    font-size:15px;
    color:rgb(36, 36, 36);
}
.header2{
    background-color:rgb(255, 255, 255);
    font-family: montserrat,'sans-serif';
    padding:4px 20px;
    font-size:24px;
    
   
    z-index:-5;
}


.header1{
    background-color:rgb(255, 255, 255);
    font-family: montserrat,'sans-serif';
    padding:20px 40px;
    font-size:24px;
    font-weight:700;
    border-bottom:1px solid rgb(224, 224, 224);
    z-index:10;
}



.table-head{
    font-size:15px;
    margin-left:60px;
    font-family: montserrat,'sans-serif';
   font-weight:600;
   width:225px;
   padding:10px;
   word-break:break-word !important;
   background-color: white;
   text-align:center;
    
}

.table-item {
    margin: 2px 10px;
    font-size: 14px;
    padding: 10px 5px;
    width:225px;
    word-break:break-word !important;
    text-align: center;
    background-color: #e8e9e930;
    font-family: montserrat,'sans-serif';
}

.editButton{
    background-color: rgb(255, 208, 0) !important;
   border:none;
   padding:0px 0px 0px 10px;
   width:55px;
   color:black;
}

.pd-2{
    padding: 2px 20px !important;
}
.mt--1{
    margin-top: -12px !important;
  }
.pull-right{float:right;}
.full-width{
    width: 100%;
}
.deleteButton{
    background-color: #ed0e0e00 !important;
    border:none;
    padding:0px 0px 0px 10px;
    width:55px;
    color:rgb(37, 37, 37) !important;
}

.deleteUser{
    
    padding:4px 10px !important;
    color:rgb(247, 247, 247) !important;
    border-radius:3px !important;
    background-color:#ee5151 !important;
    
    border:none;
    font-weight: 600;
    margin-top:4px;
    
}
.TrashButtonColumn{
    margin-left: 10px;
    border: 1px solid rgb(220 217 217);
   padding:5px 10px 5px 3px !important;
   width:35px;
   background-color: white !important;
   color:rgb(199 196 196) !important;
}

.url{
    background-color: rgb(255, 102, 55) !important;
   border:none;
   border-radius:0px;
   padding:0px 0px 0px 10px;
   width:55px;
   font-weight:600;
}
.add-button {
    background-color:   rgb(255 255 255)  !important;
    border: none;
    margin-left: 8px;
    border-radius: 3px;
    margin-bottom: 4px;
    font-size: 13px;
    padding: 5px 0px 5px 20px;
    width: 110px;
}
.saveButton{
    background-color: rgb(24, 192, 128) !important;
   border:none;
   padding:0px 0px 0px 10px;
   width:55px;
}

.table-head1{
    font-size:14px;
    margin-left:60px;
    font-family: montserrat,'sans-serif';
   font-weight:600;
 
   padding:10px;
   background-color: white;
   text-align:center;
    width:50px;
}

.table-item1 {
    margin: 2px 10px;
    font-size: 14px;
    padding: 10px 24px;
    border: 1px solid rgb(198, 206, 207);
    text-align: center;
   
    font-family: montserrat,'sans-serif';
}
.doc-head-row{
    padding:10px 0px;
    border-bottom:1px solid rgb(230, 230, 230);
}

ul{
    padding-inline-start: 0px;
}

.date-text{
font-size:15px;
font-weight:400;
font-family:Montserrat,'sans-serif';
}

.from-text{
    font-size:17px;
    font-weight:400;
    font-family:Montserrat,'sans-serif';
}
.message-text{
        font-size:16px;
        font-weight:400;
        font-family:Montserrat,'sans-serif';
        margin:10px 0px 0px 0px;
        }
.subject-text{
    margin:-30px 0px 0px -20px;
            font-size:24px;
            font-weight:600;
            font-family:Montserrat,'sans-serif';
            }

.RED-mail{
    background-color: rgb(255, 193, 193);
}


.GREEN-mail{
    background-color: rgb(215, 255, 205);
}

.PURPLE-mail{
    background-color: rgb(138, 52, 250);
}

.YELLOW-mail{
    background-color:rgb(255, 251, 193);
}



.BLUE-mail{
    background-color: rgb(213, 235, 253);
    
}


.message-box{
    padding: 10px 30px !important;
    
    display: flex;
    width:430px;
    z-index:10;

}

.btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    padding: 4px 10px !important;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.btn-primary{
    background-color: rgb(10, 151, 245) !important;
    margin:70px 0px 150px 0px;
}
.send-big-btn {
    margin: 0px 0px 0px 7px;
    width: 85px;
    border: none;
    height: 37px;
    font-size: 16px;
}

a:hover{
color:inherit !important;
}

.panel-buttons{
    background-color: #ffffff;
    padding: 10px;
    border-radius: 5px;
    color: black;
    font-size: 15px;
    border:1px solid grey;
    margin-bottom: 10px;
    margin-top: 50px;
    font-family:Montserrat,'sans-serif';
}

.back-buttons{
    background-color: #ffffff;
    padding: 10px;
    border-radius: 5px;
    color: black;
    font-size: 15px;
    border:1px solid grey;
    margin-bottom: 10px;
    margin-top: 20px;
    font-family:Montserrat,'sans-serif';
}

.butn{
    cursor:pointer;
}

.modal{
    z-index:10;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    background-color: #000;
}
.cross-row{
    padding:4px;
    background-color: rgb(233, 233, 233)
}

.upload-box{
    font-size: 12px;
    margin-top: 2px;
    margin-top: 1px;
    border-radius: 3px;
    padding: 13px;
    border: 1px solid rgb(243 240 240) !important;
    padding: 7px;
    
}
.chat-button {
    background-color: #fafafa;
    border-radius: 50px;
    height: 40px;
    border: none;
    width: 170px;
    color: #3f3f3f;
    font-weight: 600;
    font-size: 17px;
    margin-left: 70px;
    margin-top: 60px;
    box-shadow: 0px 0px 1px 1px #dedede;
}

.button-type{
    background-color: rgb(255, 255, 255) !important;
    color:rgb(32, 32, 32);
    padding:7px 8px 0px 8px;
    border-radius:5px;
    border:none;
}
.download-button > button{
   
    margin-top: -12px;
    margin-left: -8px;
    font-size: 13px;
    font-family: 'montserrat';
    width: '190px';
    font-weight: 600;
    margin-top: 0px !important;
    border-radius: 6px;
    box-shadow: 2px 1px 9px 0px #e1e1e1;
    padding: 3px 10px 4px 10px !important;
    color: rgb(255 255 255) !important;
    background-color: rgb(14 186 154) !important;
    border: none;

}

input, button, select, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    border: 1px solid #e8e8e8;
}
.download-button-wrapper {
    margin-top: -12px;
    margin-left: -8px;
    font-size: 13px;
    font-family: 'montserrat';
    width: '190px';
    font-weight: 600;
    margin-top: 0px !important;
    border-radius: 6px;
    box-shadow: 2px 1px 9px 0px #e1e1e1;
    padding: 3px 10px 4px 10px !important;
    color: rgb(255 255 255) !important;
    background-color: rgb(14 186 154) !important;
    border: none;
}
.submit-button {
    margin-top: -12px;
    margin-left: 23px;
    font-size: 13px;
    font-family: 'montserrat';
    width: '190px';
    font-weight: 600;
    margin-top: 11px !important;
    border-radius: 6px;
    box-shadow: 2px 1px 9px 0px #e1e1e1;
    padding: 3px 10px 4px 10px !important;
    color: rgb(255 255 255) !important;
    background-color: rgb(14 186 154) !important;
    border: none;
}

.masterMail-page{
    border-Top:1px solid rgb(245, 245, 245);
    border-Left:1px solid rgb(245, 245, 245);
    border-Right:1px solid rgb(245, 245, 245);
    height:590px;
    overflow-y:scroll;
}

.overflow-auto{
overflow-y:scroll;

}

a:hover{
    color:inherit !important;
}
a:active{
    color:inherit !important;
}

.form-label{

    margin:8px;
    font-size: 17px;
}

.select-form {
    background-color: #ffffff !important;
    color: #332b2b !important;
    padding: 0px 2px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    margin: 5px;
    border: 1px solid rgb(209, 209, 209);
    width: 100%;
    transition: all 0.5s ease-in-out;
    border-radius: 5px 5px 5px 5px;
}
.control-label{
    font-size:15px;
    font-weight:500;
    font-family:montserrat,'sans-serif';
    margin-top:6px;
    color:#696868;
}

.form-group{
    padding:22px 20px;
}

.form-group-workflow{
    margin-top:23px;
    margin-bottom:31px;
}
.add-header {
    font-weight: 600;
    margin-bottom: 50px;
    margin-top:40px;
    font-size: 18px;
    color: rgb(32, 32, 32);
    font-family: montserrat,'sans-serif';
    background-color: #e5eeef;
    padding: 10px 49px;
}

.khub_blg_seo-c{
    font-size: 15px;
    font-weight: 600;
    font-family: montserrat,'sans-serif';
    margin-top: 6px;
    color: #696868;
}


.add-customer-table{
    margin:10px;
}
.add-module-table{
    margin:0px 80px 0px 0px;
}





.checkList{
    list-style-type:none;
}

.check-title{
    font-weight:600;
    font-size:17px;
    margin-left:10px;
    font-family: montserrat,'sans-serif'
}

.checkList-item{
    margin:10px 0px;
    background-color:#EAEAEA;
    width: 600px;
    padding:7px;
}

.progressLogs>li{
    border:2px solid rgb(233, 237, 238);
    margin:10px 10px;
    padding:10px;
  
}

.doc-head-row1{
    padding:15px;
}

.issueLogs{
     border:1px solid red;
}

.fieldbox2{
    width:98%;
}

.card-head{
font-size:22px;
}
.rt-td{
    border-top: 1px solid rgb(228, 228, 228);
    border-left: 1px solid rgb(228, 228, 228);
}

.mt-10{margin-top: -30px !important;}


.is-fullscreen{
    position: fixed;
    top: 0;
    overflow: auto;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    background: #ffffff;
}

.dedicated-div{
    transition: all 0.3s ease-in-out;
    margin-top:-30px;
}

/* ----------------sticky header---------------- */
.sticky{

    position: sticky;
    top: 0px;
    z-index: 100;
    width: 100%; }

    .sticky2 {
        position: sticky;
        top: 64px !important;
        margin-bottom: 5px;
        z-index: 100;
        width: 100%;
    }
.ReactModal__Overlay   {
 z-index:9999999;

} 


.avatar{
    border-radius: 100%;
    width: 200px;
    height: 200px;
}

.red-btn{
    background-color: #66bb6a !important;
    padding: 1px 0px 5px 2px !important;
    margin-left: 4px !important;
}

.team-li{
    margin: 2px;
    list-style: none;
}
.max-200{
    padding: 20px;
    max-height: 200px !important;
    overflow: auto;
}

.color-block{
    height: 30px;
    width: 38px;
    display: block;
    background: #919191;
    border: 1px solid #ecec;
}

.no-padding{
    padding: 0px !important;
}





/*finall css*/
.multioptions-row {
    border-top: 1px solid #e7e7e7;
   /* box-shadow: 2px 1px 9px 0px #e1e1e1;*/
}

.options-row {
    height: 60px;
    padding: 20px 50px;
    
     
}
.options{
    text-align:center;
    padding:8px 0px;
   
    color:rgb(255, 255, 255);
    font-size:16px;
    font-family:montserrat,'sans-serif';
    font-weight:500;
}
.options:hover{
    cursor:pointer !important;
}
.activeOption{
    text-align:center;
    padding:8px 0px;
   
  
    font-size:16px;
    font-family:montserrat,'sans-serif';
    font-weight:500;
    background-color: #ebf8fa00 ;
      
        color: #ffffff!important;
   
}
.activeOptionAss{
    text-align:center;
   
   
  
    font-size:16px;
    font-family:montserrat,'sans-serif';
    font-weight:500;
    background-color: #ebf8fa00 ;
      
        color: #ffffff!important;
   
}

.blg-list-container{
    height: 80vh;
    overflow-y: hidden;
    overflow-x: scroll;
}

.dedicated-page2 {
    padding: 0px 0px;
    margin-left: -14px;
    overflow-x: hidden;
    height: calc(90vh - 120px);
   border-radius: 5px;
    background-color: white;
}


.row-resource-head{
color: #ffffff;
font-size: 17px;
padding:2px 0px 2px 7px !important;
background-color: #52BA9B;


}
.resource-head {
    color: #ffffff;
    font-size: 15px;
    font-weight: 600;
    margin-left: 12px;
    margin-top: 12px !important;
}

.content-row{
    background-color:  #ffffff;
    padding:0px 0px 30px 19px;
 }

 .badge{
    padding:7px 17px !important;
    background-color: #52BA9B !important;
    border-radius:4px !important;
    border:none;
    color:white !important;
    text-align:center;
    box-shadow:none;
    font-size:14px;
    margin-left:10px;
    margin-top:4px;
}

.doc-section{
    background-color: #ffffff;
    
    height:auto;
    overflow:scroll;
}

.sidebar-link-head {
    color: rgb(102, 102, 102) !important;
    text-decoration: none !important;
    padding-bottom: 0px;
    padding-top: 5px;
    font-size: 15px;
    margin-left: 0px;
}

.fa-plus{
    color:#52BA9B
}

.icon-carddd{
    color:#52BA9B
}

.card-shadow{
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.144);
    border-radius: 5px;
  }
  .bottom-border{
    border-bottom: 1px solid rgb(230, 230, 230);
  }

  .quill {
      height:120px !important;
  }

  .sidebar-secondary{
    position: absolute;
  /*   background: #f5f5f5; *//* was looing funny */
    background: #fff;  
    top: 84px;
    width: 88%;
    color: #fff !important;
    height: 100%;
    left: 18px !important;
    
  }
  .sidebar-secondary-access{
    position: absolute;
  /*   background: #f5f5f5; *//* was looing funny */
    background: #fff;  
    top: 46px;
    width: 88%;
    color: #fff !important;
    height: 100%;
    left: 18px !important;
    
  }

  .sidebar-secondary-webzy{
    position: absolute;
  /*   background: #f5f5f5; *//* was looing funny */
    background: #fff;  
    top: 36px;
    width: 88%;
    color: #fff !important;
    height: 100%;
    left: 18px !important;
    
  }

  .sidebar-back-arrow:before{
    content: "\f060" !important;
  }

  .oneTab{
    
    left: -300px;
  }


  /*webzy*/

  .webzy-delete-website{
   
        border:none !important;
        padding:4px 16px !important;
        color:rgb(255, 255, 255) !important;
        border-radius:3px;
        background-color:rgb(252, 11, 11) !important ;
       
        border:none;
        font-weight: 700;
        margin-top:4px;

  }

  a{
      color: inherit !important;
  }

  .no-info{
   text-align: center;
   margin-top:20px;
   font-size:17px;
   font-family: montserrat;
  }
  textarea.form-control.ember-text-field {
    max-width: 100% !important;
    resize:vertical;
  }