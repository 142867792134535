/* body{
    background-color: #f2f5f2;;
} */

body{
    font-family: "lato";
}

.user-background-image{
    height: 400px;
    background-size: cover;
}

.user-profile-container{
    position: relative;
    min-height: 220vh;
    height: auto;
    /* background-color: #f2f5f2; */
}

.user-detail{
    margin-top: -14%;
    background-color: white;
    width: 80%;
    border-radius: 4px;
    position: absolute;
    margin-left: 10%;
    padding-bottom: 3%;
}

.user-profile-image{
    width: 100%;
    height: 270px;
}

.spacing-user-detail{
    margin: 2%;
}

.about-user-inf-t{
    font-size: 16px;
    font-weight: 700;
    width: 50%;
    font-family: 'Open Sans', sans-serif;
    padding-bottom:8%;
}

.user-inf-t{
    font-size: 15px;
    font-weight: 700;
    width: 46%;
    font-family: 'Open Sans', sans-serif;
    padding-bottom: 5%;
}

.user-inf-d{
    font-size: 14px;
    padding-bottom:2% ;
    padding-left: 5%;
    font-family: 'Open Sans', sans-serif;
}

.user-inf-a{
    font-size: 14px;
    padding-bottom:2% ;
    padding-top: 1%;
    border-left: 1px solid #cfcaca;
    font-family: 'Open Sans', sans-serif;
    line-height: 25px;
}

.user-header-name{
    padding-bottom: 1%;
    border-bottom: 1px solid #cfcaca;
    /* margin-left: 25%; */
    margin-bottom: 2%;
    color: #2a2fd2;
}

a{
    color: #23527c;
}

.profile-tab-c{
    display: flex;
    list-style: none;
    justify-content: space-between;
}

.profile-tab{
    width: 100%;
    background-color: #f7f7f7 !important;
    padding: 2% 22%;
    font-weight:700 ;
    margin-bottom: 4%;
    position: relative;
    text-align: center;
    flex-grow:1;
    flex-basis: 0;
    transition: .3s border-color;
    border-right: 2px solid white;
}

.profile-tab a{
    transition: .3s all;
    font-size: 18px;
    display: block;
    width: 100%;
    padding: 25px 5px;
}

.ml-1{
    margin-left: 5%;
}
@media (min-width:720px){
    .ml-1{
        margin-left: 25%;
    }
}

.px-4{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.py-1{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.w-12{
    width: 7.5rem;
}

@media (min-width:720px){
    .w-12{
        min-width: 8.5rem;
    }
}

.profile-tab-a{
    position: absolute;
    left: 0;
    top: 0;
    align-items: center;

}

.profile-tab.active{
    color: #2a2fd2 !important;
    border-bottom:4px solid #2a2fd2;
}

.tab-heading{
    background: #2a2fd2 !important;
    color: white;
    width: fit-content;
}

.tab-heading-m{
    border-bottom: 2px solid #2a2fd2;
    margin-bottom: 2%;
}

.tab-heading-client-c{
    border-bottom: 2px solid #2a2fd2;
}

.leave-client-comment{
    padding:2%;
    background-color: #ececec;
    border-bottom: 1px solid #cfcaca;
}

.open-modal{
    display: block;
    top: 50px;
}

.hide{
    display: none;
}

.show{
    display: block;
}

.modal-dialog{
    position: relative;
}

.modal.in.modal-dialog{
    transform: translate(0,0);
}

.modal.fade.modal-dialog{
    transition: transform .3s ease-out;
}

.modal-content{
    border-radius: 5px !important;
    border: none !important;
    padding: 20px 30px;
}

.form-box{
    padding-bottom: 8%;
}

.form-box_row{
    margin: 10px;
}

.form-box_row textarea{
    width: 100%;
    height: 175px;
    font-size: 14px;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #edebeb;
    padding: 10px 22px;
}

.form-box .row input[type=text]{
    text-align: inherit !important;
    padding: 15px 20px !important;
}

.btn-send-feedback{
    border: none;
    background: #8686f9;
    border-radius: 4px;
    padding: 6px 14px;
    color: #fff;
}

.submit-check{
    padding: 4% 45%;
    font-size: 72px;
    color: green;
}

.about{
    padding-left: 2%;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    column-count: 2;
    column-gap: 25px;
}

.maintab{
    border-bottom: 2px solid #c2c2c2;
}

.tab-dropdown{
    background-color: inherit;
    padding: 1% 3%;
    border: none;
    color:#da1f1f ;
}

.tab-d-active{
    font-weight: 700;
    color: #2a2fd2;
}

/* .tab-d-active::after{
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #c2c2c2;
    content: '';
    position: absolute;
    bottom: -5px;
    left: 8%;
    margin-left: -5px;
} */

.maintab-content-t{
    color: #2a2fd2 ;
    font-size: 16px;
}

.add-user-i-active{
    background-color: #2a2fd2 !important ;
    font-weight: 700;
}

.add-user-info{
    cursor: pointer;
    border-left: 2px solid white;
    background-color: #e7e7e7;
    padding: 1% 2%;
    color: white;
}

.add-user-container{
    margin-top: 5%;
    background-color: white;
    padding: 2%;
    border-radius: 4px;
    box-shadow: 0px 0px 3px 1px #e8e3e3;
    width: auto !important;
}

/* input[type=password],
input[type=text],input[type=number] {
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 5px;
    width: 85%;
    transition: all .5s ease-in-out;
    border-radius: 5px 5px 5px 5px;
    background-color: #fff;
    color: #444!important;
    border: 1px solid #edebeb;
} */

.education-d{
    border-bottom: 2px solid #c2c2c2;
    padding-bottom: 6%;
}

.courseType{
    border: none;
    padding: 2% 4%;
    margin-right: 2%;
    font-size: 16px;
    border-radius: 3px;
}

.courseType-active{
    background-color: #2a2fd2;
    color: #fff;
}

.experience-d{
    padding-top: 2%;
    border-bottom: 2px solid #dad4d4;
}

.form-control1{
    display: block;
    width: 45%;
    padding: .375rem .75rem;
    font-size: 13px;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    font-weight: 400;
    height: auto;
    margin: 2%;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.skill-button{
    border: none;
    padding: 1%;
    margin-left: 2%;
    font-size: 16px;
    font-weight: 700;
    color: white;
    background-color: #2a2fd2;
    border-radius: 5px;

}

.container .stepper-bar{
    display: flex;
    /* margin: 40px 0; */
}

.container .stepper-bar .step{
    position: relative;
    text-align: center;
    width: 100%;
}

.stepper-bar .step p{
    font-size: 18px;
    font-weight: 500;
    color: #000;
    margin-bottom: 8px;
    transition: 0.2s;
}

.stepper-bar .step p.active{
    color: #2a2fd2 !important;
}

.stepper-bar .step .bullet{
    position: relative;
    height: 25px;
    width: 25px;
    border: 2px solid #000;
    display: inline-block;
    border-radius: 50%;
    transition: 0.2s;
}

.stepper-bar .step .bullet.active{
    border-color:  #2a2fd2;
    background: #2a2fd2 !important;
}

.stepper-bar .step:last-child .bullet::before,
.stepper-bar .step:last-child .bullet::after{
    display: none;
}

.stepper-bar .step .bullet::before,
.stepper-bar .step .bullet::after{
    position: absolute;
    content: "";
    height: 3px;
    width: 125px;
    background: #262626;
    bottom: 11px;
    right: -130px;
}

.stepper-bar .step .bullet.active::after{
    background: #2a2fd2;
    transform: scaleX(0);
    transform-origin: left;
    animation: animate 0.3s linear forwards;
}

@keyframes animate{
    100%{
        transform: scaleX(1);
    }
}

.stepper-bar .step .bullet span{
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.stepper-bar .step .bullet.active span{
    display: none;
}

.stepper-bar .step .check{
    position: absolute;
    left:50%;
    top: 70%;
    font-size: 15px;
    transform: translate(-50%, -50%);
    display: none;
}

.stepper-bar .step .check.active{
    display: block;
    background-color: transparent !important;
    color: #fff !important;
}

.right-float{
    float: right;
    margin-right: 3%;
    margin-top: 6%;
}

.container .nxt-button{
    border: none;
    padding: 1% 3%;
    font-size: 17px;
    background: #2a2fd2;
    color: white;
    border-radius: 5px;
}

.container .prev-button{
    border: none;
    padding: 1% 3%;
    font-size: 17px;
    color: #222121;
    border-radius: 5px;
}

.container .nxt-button :hover{
    background: #000 !important;
}


.container .prev-button :hover{
    background: #000 !important;
}

.profileImageUrl{
    width: 65%;
    height: 30vh;
    margin-bottom: 1%;
}

.coverImageUrl{
    width: 75%;
    height: 25vh;
    margin-top: 2%;
    padding-bottom: 1%;
}

.previewProfileUrl{
    width: 72%;
    height: 30vh;
    margin-bottom: 1%;
}

.previewCoverUrl{
    width: 18vw;
    height: 18vh;
    margin-top: 23%;
}

.r-profile-image{
    width: 45%;
    height: 30vh;
    border: 2px solid #dad4d4;
}

.preview-boxes{
    margin-left: 2%;
    padding-bottom: 3%;
    border-bottom: 2px solid #dad4d4;
}

.adjust-preview-p{
    padding-bottom: 2%;
}

.adjust-preview-p .control-label{
    font-size: 14px;
}

.user-profile-container .all-profile-box{
    background: white;
    border-radius: 5px;
}

.maintab-content-st {
    font-size: 14px;
}

.all-profile-t{
    font-size: 18px;
    font-weight: 600;
    color: #636466;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 8px;
    border-bottom: 1px solid #bdbdbe;
    margin-bottom: 10px;
    cursor: pointer;
    width: 17vw;
}

.all-profile-t:hover{
    color: #2a2fd2;
}

.all-profile-st{
    color: #636466;
    font-size: 15px;
    font-weight: 600;
    padding: 0 0 0 15px ;
}

.all-profile-st-v{
    color: #636466;
    font-size: 14px;
    margin: 2% 0;
    padding:0;
}

.all-profile-img-container{
    height: 40vh;
    width: 85vw;
    position: relative;
    z-index: 1;
    overflow: hidden;
}


.profile-hover-text{
    position: absolute;
    color: white;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    margin-top: -13%;
    min-height: 11vh;
    height: auto;
    width: 20%;
    transform: translate(0,100%);
    background: rgba(54,105,179,.8);
    transition: transform 0.4s ease ;
}

.all-profile-img-container:hover .profile-hover-text{  
    /* display: block !important; */
    transform: translate(0,0);

}

.product-hover-container{
    position: absolute;
    color: white;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    margin-top: -13%;
    height: 40vh;
    width: 20%;
    transform: translate(0,100%);
    background: rgba(54,105,179,.8);
    transition: transform 0.4s ease ;
}

.all-profile-img-container:hover .product-hover-container{
    transform: translate(0,0);
}

.ce-paragraph{
    padding-bottom: 3%;
}

/* @media only screen and (min-width:720px){
    .ce-profile-child{
        width: 50%;
        padding-right: 2%;
        padding-bottom: 2%; 
        float: left;
    }
} */

/* .ce-profile-parent{
    padding-bottom: 3%;
} */

@media (max-width:720px){
    .ce-profile-parent{
        padding: 3% 4% 3% 3%;
    }
}

@media (max-width:720px){
    .small-d{
        display: none !important;
    }
}

.preview-bio-p{
    height: 65vh;
    overflow: scroll;
}

.remove-profileImage{
    position: absolute;
    font-size: 32px;
    font-weight: 700;
    cursor: pointer;
}

.user-profile-edit{
    float: right;
    border: none;
    padding: 1% 2%;
}

.about-user-container-d{
    padding: 2% 4%;
    
}

.about-user-title-d{
    font-size: 23px;
    font-weight: 700;
    border-bottom: 1px solid blue;
    color: #3b5d6b;
}

.about-user-content-d{
    margin-top: 3%;
}

.product-d{
    padding-bottom: 2%;
    border-bottom: 1px solid #dcd8d8;
}

.uploadProductImage{
    background: #bbd7f5;
    font-size: 16px;
    padding: 1% 6%;
    border-radius: 3px;
}

.preview-product-card{
    width: 100%;
    padding: 8% 14%;
    background: #e7e7e7;
}

.preview-product-image{
    width: 180px;
    height: 160px;
    margin-bottom: 5%;
}

.booking-day{
    border: 1px solid #cecece;
    padding: 1% 2%;
    background: white;
    margin-right: 2%;
    margin-bottom: 2%;
}

.booking-timing{
    border: 1px solid #cecece;
    background: white;
    margin-bottom: 2%;
    font-size: 14px;
    margin-right: 1%;
}

.active-booking{
    background: #1919ed !important;
    color: white;
    border: none !important;
}

.currentActiveBooking{
    background: #003a90b3;
    color: white;
    border: none;
}

.active-timing{
    background: #1919ed !important;
    color: white;
    border: none !important;
}

.user-booking-day{
    border: 1px solid #d2d2d2;
    background: white;
    margin-bottom: 2%;
    width:60px;
    margin-right: 2%;
    font-size: 14px;
}

.user-booking-day-active{
    color: white !important;
    background: #2a2fd2 !important;
}

.user-booking-time{
    border: none;
    margin-bottom: 2%;
    padding: 1% 10%;
}


.booking-container{
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    width: 1170px;
    padding-top: 8%;
}

.booking-lawyer-d{
    font-weight: 700;
    padding-bottom: 3%;
    border-bottom: 1px solid #d6d5d5;
}

.float-r{
    float: right;
}

.font-b{
    font-weight: 700;
}

.form-control-booking{
    display: block;
    width: 100%;
    padding: 2rem .75rem;
    font-size: 13px;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    font-weight: 400;
    height: calc(1.5em + .75rem + 2px);
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin-bottom: 5%;
}

.confirm-booking-box{
    border: 1px solid #d6d5d5;
    padding:0 ;
    background: white;
}

.confirm-booking-box-h{
    padding: 2% 0 2% 4%;
    border-bottom: 1px solid #d6d5d5;
}

.book-consultancy{
    border: none;
    padding: 2% 24%;
    color: white;
    background:  #2a2fd2;
}

.bookingSlot-tab-t{
    padding: 10px 200px;
    cursor: pointer;
}

.bookingSlot-tab-active{
    background: blue !important;
    color: white;
}

.consultationType{
    border: none;
    padding: 12px 15px;
    margin-right: 2%;
    font-size: 16px;
}

.consult-active{
    background: blue;
    color: white;
}

.bt-fields{
    margin-top: 3%;
}

.userConsult{
    float: right;
    border: none;
    font-size: 15px;
    padding: 5px 15px;
    background: #2fa22fcf;
    color: white !important;
    border-radius: 5px;
}

.booking-outer-date .booking-inner-date{
    display: inline;
}

.booking-outer-time .booking-inner-time{
    display: inline;
}

.copyDayTime{
    background: blue;
    color: white;
    border-radius: 4px;
    border: 1px solid blue;
    width: 7vw;
    height: 6vh;
    margin-bottom: 2%;
}

.copySubmitBtn{
    position: absolute;
    border: none;
    padding: 1% 3%;
    font-size: 17px;
    background: #2a2fd2;
    top: 290px;
    left: 280px;
    color: white;
    border-radius: 5px;
}

.sidebar-all{
    height: 100vh;
    box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
    margin-left: -15px;
    background: #104790;
    color: white;
}

.sidebar-inner-all{
    padding-top: 5%;
}

.sidebar-inner-all .sidebar-p-home{
    border-bottom: 1px solid #d9d8d8;
    padding-left: 8%;
    padding-bottom: 5%;
}

.link-c{
    color: white;
}

.link-c :hover{
    color: white !important;
}

.edit-courseType{
    border: none;
    padding: 1% 2%;
    margin: 2% 0 2% 2%;
    font-size: 16px;
    border-radius: 3px;
}