/*!
 * Start Bootstrap - Agency v5.0.3 (https://startbootstrap.com/template-overviews/agency)
 * Copyright 2013-2018 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-agency/blob/master/LICENSE)
 */

body {
  overflow-x: hidden;
  font-family: 'Roboto Slab', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.bg-light{
  
    background-color:#d4ddff!important;

}
p {
  line-height: 1.75;
}

a{

}

a:hover{

}


.text-primary {
 color: #fec503 !important;
}

.text-primary1 {
  color:#00A5B6!important;
 }
 
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

section {
  padding: 30px 0;
}

section h2.section-heading {
  font-size: 23px;
  font-weight: 700;
  margin-top: 0;
 
  bottom: 28px;
  
  margin-bottom: 30px;
}

section h3.section-subheading {
  font-size: 18px;
  font-weight: 400;
  
  margin-bottom: 75px;
  text-transform: none;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

@media (min-width: 768px) {
  section {
    padding: 110px 0;
  }

  .sortSection{
    width:17vw;
  }
}

.btn {
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: 700;
}

.btn-xl {
  font-size: 18px;
  padding: 20px 40px;
}

.btn-primary {
  background-color: #0077ff00;
  border-color:none !important;
}




.btn-primary:active, .btn-primary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(254, 209, 55, 0.5) !important;
  box-shadow: 0 0 0 0.2rem rgba(254, 209, 55, 0.5) !important;
}

::-moz-selection {
  background: #fed136;
  text-shadow: none;
}

::selection {
  background: #fed136;
  text-shadow: none;
}

img::-moz-selection {
  background: transparent;
}

img::selection {
  background: transparent;
}

img::-moz-selection {
  background: transparent;
}

#mainNav {
  background-color: #212529;
/*   margin-top:45px; */
}

#secNav {
  height: 60px;
  margin-top: 0px;
  padding-top: 16px;
  padding-bottom: 2px;
  transition: padding-top 0.3s, padding-bottom 0.3s;
  border: none;
  background-color: #2680ee;
}
#topNav {
  background-color: #212529;
  
}
#mainNav .navbar-toggler {
  font-size: 11px;
  right: 0;
  padding: 13px;
  
  text-transform: uppercase;
  color: white;
  border: 0;
  background-color: #fed136;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

#mainNav .navbar-brand {
  color:  #ffffff;;
  font-family: 'Kaushan Script', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

#mainNav .navbar-brand.active, #mainNav .navbar-brand:active, #mainNav .navbar-brand:focus, #mainNav .navbar-brand:hover {
  color: #ffffff;
}

#mainNav .navbar-nav .nav-item .nav-link {
  font-size: 100%;
  font-weight: 600;
  padding: 0.75em 0.75em;
  letter-spacing: 1px;
  color: white;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

#secNav .navbar-nav .nav-item .nav-link {
  font-size: 91%;
  font-weight: 600;
  padding: 0.75em 0.75em;
  letter-spacing: 1px;
  color: white;
  font-family: 'montserrat';
}
#secNav .navbar-nav .nav-item .nav-link.active, #mainNav .navbar-nav .nav-item .nav-link:hover {
  color: #fed136;
  
}
#mainNav .navbar-nav .nav-item .nav-link.active, #mainNav .navbar-nav .nav-item .nav-link:hover {
  color: #fed136;
  
}

#topNav .navbar-toggler {
  font-size: 11px;
  right: 0;
  padding: 13px;
  
  text-transform: uppercase;
  color: white;
  border: 0;
  background-color: #fed136;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

#topNav .navbar-brand {
  color:  #ffffff;;
  font-family: 'Kaushan Script', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

#topNav .navbar-brand.active, #mainNav .navbar-brand:active, #mainNav .navbar-brand:focus, #mainNav .navbar-brand:hover {
  color: #ffffff;
}

#topNav .navbar-nav .nav-item .nav-link {
  font-size: 53%;
  font-weight: 300;
  padding: 0.75em 0;
  letter-spacing: 1px;
  color: rgb(14, 13, 13);
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

#topNav .navbar-nav .nav-item .nav-link.active, #mainNav .navbar-nav .nav-item .nav-link:hover {
  color: #fed136;
  
}
@media (min-width: 992px) {
  #mainNav {
    height:60px;
    margin-top:0px;

    padding-top: 2px;
    padding-bottom: 2px;
    -webkit-transition: padding-top 0.3s, padding-bottom 0.3s;
    transition: padding-top 0.3s, padding-bottom 0.3s;
    border: none;
   
    background-color:  #2680ee;
  }
  #mainNav .navbar-brand {
    font-size: 20px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  #mainNav .navbar-nav .nav-item .nav-link {

    margin-top:20px;
  }
  #mainNav.navbar-shrink {
    padding-top: 0;
    padding-bottom: 0;
    background-color: #212529;
  }
  #mainNav.navbar-shrink .navbar-brand {
    font-size: 1.25em;
    padding: 12px 0;
  }

.color{
  margin-top:14px
}
.panel-item{
  font-size:20px;
  font-weight:500;
  list-style: none;
  color:black;
  margin-top:7px;
  transition: all 0.3s ease-in-out;
}

.panel-side-item{
  font-size:20px;
  font-weight:500;
  color:black;
  transition: all 0.3s ease-in-out;
  right:0;
}
.panel-item:hover{
  font-size:20px;
  font-weight:800;
  color:rgb(255, 255, 255);
  height:45px;
  cursor:pointer;
  
}

.panel-side-item:hover {
  font-size:20px;
  font-weight:800;
  color:rgb(247, 244, 244);

  cursor:pointer;

}

#topNav {
  height:47px;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-transition: padding-top 0.3s, padding-bottom 0.3s;
  transition: padding-top 0.3s, padding-bottom 0.3s;
  border: none;
  box-shadow: 2px 2px 20px 2px #7c6e6e85;
  background-color: #bff305;
  z-index: 5000;
}
#topNav .navbar-brand {
  font-size: 20px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
#topNav .navbar-nav .nav-item .nav-link {
  padding: .5em 1em !important;
}
#topNav.navbar-shrink {
  padding-top: 0;
  padding-bottom: 0;
  background-color: #212529;
}
#Nav.navbar-shrink .navbar-brand {
  font-size: 1.25em;
  padding: 12px 0;
}
}

header.masthead {
  text-align: center;
  color: white;
  background-image: url("../img/wallpaper.jpg");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
}

header.masthead .intro-text {
 
}

header.masthead .intro-text .intro-lead-in {
  font-size: 22px;
  
  line-height: 22px;
  margin-bottom: 25px;
  font-family:lato,'sans-serif';
}

header.masthead .intro-text .intro-heading {
  font-size: 50px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 25px;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

@media (min-width: 768px) {
  header.masthead .intro-text {
 
   
    
    background-color:'rgba(182, 0, 141, 0)';
  }
  header.masthead .intro-text .intro-lead-in {
    font-size: 40px;
    font-weight:300;
    line-height: 40px;
    margin-bottom: 25px;
    font-family:lato,'sans-serif';
  }
  header.masthead .intro-text .intro-heading {
    font-size: 75px;
    font-weight: 700;
    line-height: 75px;
    margin-bottom: 50px;
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  }
}

.service-heading {
  margin: 20px 0px 5px 14px;
  text-transform: none;
  font-size: 20px;
  font-weight:800;
}

.team-heading {
  margin: 34px 0px 5px 14px;
  text-transform: none;
  font-size: 19px;
  font-family: montserrat,'sans-serif';
}

.team-description {
  margin: 34px 0px 5px 14px;
  text-transform: none;
  font-size: 20px;
  font-family: montserrat,'sans-serif';
}


.webzy-testimonials-description{
font-size:14px;
font-family:montserrat,'sans-serif';
}

#portfolio .portfolio-item {
  right: 0;
  margin: 0 0 15px;
}

#portfolio .portfolio-item .portfolio-link {
  position: relative;
  display: block;
  max-width: 400px;
  margin: 0 auto;
  cursor: pointer;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  opacity: 0;
  background: rgba(254, 209, 54, 0.9);
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover:hover {
  opacity: 1;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content {
  font-size: 20px;
  position: absolute;
  top: 50%;
  width: 100%;
  height: 20px;
  margin-top: -12px;
  text-align: center;
  color: white;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content i {
  margin-top: -12px;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content h3,
#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content h4 {
  margin: 0;
}

#portfolio .portfolio-item .portfolio-caption {
  max-width: 400px;
  margin: 0 auto;
  padding: 25px;
  text-align: center;
  background-color: #fff;
}

#portfolio .portfolio-item .portfolio-caption h4 {
  margin: 0;
  text-transform: none;
}

#portfolio .portfolio-item .portfolio-caption p {
  font-size: 16px;
  font-style: italic;
  margin: 0;
  font-family: 'Droid Serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

#portfolio * {
  z-index: 2;
}

@media (min-width: 767px) {
  #portfolio .portfolio-item {
    margin: 0 0 30px;
  }
}

.portfolio-modal {
  padding-right: 0px !important;
}

.portfolio-modal .modal-dialog {
  margin: 1rem;
  max-width: 100vw;
}

.portfolio-modal .modal-content {
  padding: 100px 0;
  text-align: center;
}

.portfolio-modal .modal-content h2 {
  font-size: 3em;
  margin-bottom: 15px;
}

.portfolio-modal .modal-content p {
  margin-bottom: 30px;
}

.portfolio-modal .modal-content p.item-intro {
  font-size: 16px;
  font-style: italic;
  margin: 20px 0 30px;
  font-family: 'Droid Serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.portfolio-modal .modal-content ul.list-inline {
  margin-top: 0;
  margin-bottom: 30px;
}

.portfolio-modal .modal-content img {
  margin-bottom: 30px;
}

.portfolio-modal .modal-content button {
  cursor: pointer;
}

.portfolio-modal .close-modal {
  position: absolute;
  top: 25px;
  right: 25px;
  width: 75px;
  height: 75px;
  cursor: pointer;
  background-color: transparent;
}

.portfolio-modal .close-modal:hover {
  opacity: 0.3;
}

.portfolio-modal .close-modal .lr {
  /* Safari and Chrome */
  z-index: 1051;
  width: 1px;
  height: 75px;
  margin-left: 35px;
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #212529;
}

.portfolio-modal .close-modal .lr .rl {
  /* Safari and Chrome */
  z-index: 1052;
  width: 1px;
  height: 75px;
  /* IE 9 */
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  background-color: #212529;
}

.timeline {
  position: relative;
  padding: 0;
  list-style: none;
}

.timeline:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 40px;
  width: 2px;
  margin-left: -1.5px;
  content: '';

  background-color:#ffffff;;
}
.timeline:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50% !important;
    width: 2px;
    margin-left: 46%;
  content: '';
  background-color:#5aa1af;
  height:auto;
}

.timeline > li {
  position: relative;
  min-height: 50px;
  margin-bottom: 50px;
}

.timeline > li:after, .timeline > li:before {
  display: table;
  content: ' ';
}


.timeline > li:before {
  content: ' ';
  background:  #22ac82 ;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px
   solid #0f468d;
      left: 45%;
      top: 13px;
      width: 113px;
      z-index:220px;
      height: 109px;
      /* z-index: 10000;*/
}


/* .webzy-contact-fields > input[type=password], input[type=text]  input[type=email], input[type=number] {
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 5px;
  width: 85%;
  transition: all .5s ease-in-out;
  border-radius: 5px 5px 5px 5px;
  background-color: #fff;
  color: #444!important;
  border: 1px solid #edebeb;
}  */
.timeline > li:after {
  clear: both;
}

.timeline > li .timeline-panel {
  position: relative;
  float: right;
  width: 100%;
  padding: 0 20px 0 100px;
  text-align: left;
}

.timeline > li .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.timeline > li .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline > li .timeline-image {
  position: absolute;
  z-index: 100;
  left: 0;
  width: 80px;
  height: 80px;
  margin-left: 0;
  text-align: center;
 
}

.timeline > li .timeline-image h4 {
  font-size: 10px;
  line-height: 14px;
  margin-top: 12px;
  
}

.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
  padding: 0 20px 0 100px;
  text-align: left;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline > li:last-child {
  margin-bottom: 0;
}

.timeline .timeline-heading h4 {
  margin-top: 0;
  color: inherit;
}

.timeline .timeline-heading h4.subheading {
  text-transform: none;
}

.timeline .timeline-body > ul,
.timeline .timeline-body > p {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .timeline:before {
    left: 50%;
  }
  .timeline > li {
    min-height: 100px;
    margin-bottom: 100px;
  }
  .timeline > li .timeline-panel {
    float: left;
    width: 41%;
    padding: 0 20px 20px 30px;
    text-align: right;
  }
  .timeline > li .timeline-image {
    left: 50%;
    width: 100px;
    height: 100px;
    margin-left: -50px;
  }
  .timeline > li .timeline-image h4 {
    font-size: 13px;
    line-height: 18px;
    margin-top: 16px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    float: right;
    padding: 0 30px 20px 20px;
    text-align: left;
  }
}

@media (min-width: 992px) {
  .timeline > li {
    min-height: 150px;
  }
  .timeline > li .timeline-panel {
    padding: 0 20px 20px;
  }
  .timeline > li .timeline-image {
    width: 150px;
    height: 150px;
    margin-left: -75px;
  }
  .timeline > li .timeline-image h4 {
    font-size: 18px;
    line-height: 26px;
    margin-top: 30px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    padding: 0 20px 20px;
  }
}

@media (min-width: 1200px) {
  .timeline > li {
    min-height: 170px;
    padding:25px;
  }
  .timeline > li .timeline-panel {
    padding: 0 20px 20px 100px;
  }
  .timeline > li .timeline-image {
    width: 100px;
    height: 100px;
    margin-left: -50px;
  }
  .timeline > li .timeline-image h4 {
    margin-top: 40px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    padding: 0 100px 20px 20px;
  }
}

.container{
  width:1139 !important;
}


.team-member {
  margin-bottom: 50px;
  text-align: center;
}

.team-member img {
  width: 225px;
  height: 225px;
  border: 7px solid #fff;
}

.team-member h4 {
  margin-top: 25px;
  margin-bottom: 0;
  text-transform: none;
}

.team-member p {
  margin-top: 8px;
}




section#query {
  background-color:#10b8eb;
  background-image: url("../img/map-image.png");
  background-repeat: no-repeat;
  background-position: center;
}

section#contact {
  background-color:#ce9d2a;
  background-image: url("../img/map-image.png");
  background-repeat: no-repeat;
  background-position: center;
}
section#contact .section-heading {
  color: rgb(34, 34, 34);
}
section#query .section-heading {
  color: rgb(27, 27, 27);
}

section#contact .form-group {
  margin-bottom: 25px;
}

section#contact .form-group input,
section#contact .form-group textarea {
  padding: 20px;
  background: none !important;
}



section#contact .form-group textarea.form-control {
  height: 110px !important;
}

section#contact .form-control:focus {
  border-color: #fed136;
  -webkit-box-shadow: none;
  box-shadow: none;
}

section#contact ::-webkit-input-placeholder {
  font-weight: 700;
  color: #ffffff;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

section#contact :-moz-placeholder {
  font-weight: 700;
  color: #ffffff;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

section#contact ::-moz-placeholder {
  font-weight: 700;
  color: #ced4da;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

section#contact :-ms-input-placeholder {
  font-weight: 700;
  color: #ced4da;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}


section#query ::-webkit-input-placeholder {
  font-weight: 700;
  color: #ffffff;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

section#query :-moz-placeholder {
  font-weight: 700;
  color: #ffffff;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

section#query ::-moz-placeholder {
  font-weight: 700;
  color: #ced4da;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

section#query :-ms-input-placeholder {
  font-weight: 700;
  color: #ced4da;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}


/********************/


section#query {
  margin-top:40px;
  background-color:#312e2e;
  background-image: url("../img/map-image.png");
  background-repeat: no-repeat;
  background-position: center;
}
section#query .section-heading {
  color: rgb(34, 34, 34);
}
section#query .section-heading {
  color: rgb(37, 37, 37);
}

section#query .form-group {
  margin-bottom: 10px;
}

section#query .form-group input,
section#query .form-group textarea {
  padding: 20px;
}

section#query .form-group input.form-control {
  height: 19px;
}

section#query .form-group textarea.form-control {
  height: 193px !important;
}

section#query .form-control:focus {
  border-color: #fed136;
  -webkit-box-shadow: none;
  box-shadow: none;
}

section#query ::-webkit-input-placeholder {
  font-weight: 700;
  color: #ced4da;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

section#query :-moz-placeholder {
  font-weight: 700;
  color: #ced4da;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

section#query ::-moz-placeholder {
  font-weight: 700;
  color: #ced4da;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

section#query :-ms-input-placeholder {
  font-weight: 700;
  color: #ced4da;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.footer-head{
  font-weight:600;
  text-align:left;
}
.footer-item{
  font-size:17px;
  font-family:'lato',sans-serif;
  font-weight:400;
  margin-top:13px;
  margin-left:-2px;
  color:rgb(31, 31, 31);
}
.footer-item1{
  font-size:17px;
  font-family:'lato',sans-serif;
  font-weight:400;
  margin-top:13px;
  margin-left:-60px;
  color: rgb(133, 135, 150);

}
footer span.copyright {
  font-size: 90%;
  line-height: 40px;
  text-transform: none;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

footer ul.quicklinks {
  font-size: 90%;
  line-height: 40px;
  margin-bottom: 0;
  text-transform: none;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

ul.social-buttons {
  margin-bottom: 0;
  padding:0px;
}

ul.social-buttons li a {
  font-size: 20px;
  line-height: 50px;
  display: block;
  width: 38px;
  height: 43px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: white;
  border-radius: 10px;
  outline: none;
  background-color: #212529;
}

ul.social-buttons li a:active, ul.social-buttons li a:focus, ul.social-buttons li a:hover {

}

.tempcard{
  box-shadow: 3px 4px 14px 3px #88888821;
  border-radius:5px;
  padding:20px;
  transform:scale(1);
  height: 100%;
  transition: all 0.8s ease;
  background-color: white;
  
}

.tempcard > .description-card{
 display:none

}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  box-shadow: 3px 4px 14px 3px #88888821;
  border-radius:5px;
  padding:20px;
  transform:scale(1);
  height: 100%;
  transition: all 0.6s ease-in-out;
  background-color: #fffffff3;
}

.tempcard:hover > .overlay{
  opacity:1;
}
.tempcard1{
  box-shadow: 3px 4px 14px 3px #88888821;
  border-radius:5px;
  padding:20px;
  transform:scale(1);
  transition: all 0.8s ease;
 
}

.product-row{
  margin-top:20px;
}

.tempcard:hover > .icon  {
  color:white;
}

.cardd{
  margin-top:20px;
  
  
}

.tempcard:hover {
  box-shadow: 3px 4px 14px 3px #88888821;
 transform:scale(1.2);
 background-color: white;
 opacity:1;
 z-index:5000;
}

.cursor{
  cursor: pointer;
}

.removesign{
  
  color:#f66511;
  position:absolute;
  top:5px;
  right:20px;
  height:2px;
  width:2px;
  
}
.editclass{
  font-size:15px;
  
}
.editsign {
  position: absolute;
  top: 0px;
  
  right: 58px;
  height: 2px;
  width: 2px;
  color: rgb(0, 0, 0);
}
.buttOn{
  background-color: transparent !important;
  border:none !important;
}
.adddivsign{
  position:absolute;
 
  font-size:28px;
  top:-7px;
  right:66px;
  color:rgb(0, 0, 0);
}
.adddivsign:hover{
  cursor:pointer
}
.removedivsign:hover{
  cursor:pointer
}

.removedivsign{
  position:absolute;
  top:9px;
  right:110px;
  
  
  color:rgb(0, 0, 0);
}

.fieldbox{
  border:1px solid rgb(204, 204, 204) !important;
}

.imagebutton{
  border:1px solid rgb(182, 182, 182) !important;
  border-radius:4px;
  margin-top:10px;
}

.rounded1-circle{
  height:181px;
  width:210px;
}
.rounded-circle{
  margin-top: -9px;
    /* margin-left: 7px; */
    height: 100px;
    width: 100px;
    border-radius: 50% !important;
}
.addSection {
  position: fixed;
  width: 10vw;
  top: 30%;
  height: 50vh;
  background-color: #ffffff05;
  right: 0;
  border-radius: 10px;
  margin-right: -5px;
  z-index: 99999;
  box-shadow: 0px 1px 6px 3px #e9e9e985;
}
.navbar-second{

}
.sortSection {
  height: 50vh;
  position: fixed;
  width: 13vw;
  top: 30%;
  overflow-y: scroll;
  background-color: #ffffff00;
  left: 0;
  border-radius: 14px;
  margin-right: -4px;
  margin-left: -10px;
  padding: 9px 0px 9px 0px;
  color: #1f1f1f;
  z-index: 11999440000000;
  box-shadow: 0px 1px 6px 3px #e9e9e985;
}


.adddivsign1{
  position:absolute;
  top:6%;
  right:36px;
  text-align:center;
  
  height:30px;
  background-color: white;
  border-radius:3px;
  color:black;
  padding:3px 7px;
  width:90px;
}


.adddivsign2{
  position:absolute;
  top:18%;
  right:36px;
  text-align:center;
  
  height:30px;
  background-color: white;
  border-radius:3px;
  color:black;
  padding:3px 7px;
  width:90px;
}
.adddivsign3{
  position:absolute;
  top:30%;
  right:36px;
  text-align:center;
  
  height:30px;
  background-color: white;
  border-radius:3px;
  color:black;
  padding:3px 7px;
  width:90px;
}
.adddivsign4{
  position:absolute;
  top:42%;
  right:36px;
  text-align:center;
  
  height:30px;
  background-color: white;
  border-radius:3px;
  color:black;
  padding:3px 7px;
  width:90px;
}
.adddivsign8{
  position:absolute;
  top:54%;
  right:36px;
  text-align:center;
  height:30px;
  background-color: white;
  border-radius:3px;
  color:black;
  padding:3px 7px;
  width:90px;
}
.adddivsign5{
  position:absolute;
  top:66%;
  right:36px;
  text-align:center;
  height:30px;
  background-color: white;
  border-radius:3px;
  color:black;
  padding:3px 7px;
  width:90px;
}
.adddivsign6{
  position:absolute;
  top:78%;
  right:36px;
  text-align:center;
  height:30px;
  background-color: white;
  border-radius:3px;
  color:black;
  padding:3px 7px;
  width:90px;
}
.adddivsign7{
  position:absolute;
  top:90%;
  text-align:center;
  right:36px;
  height:30px;
  background-color: white;
  border-radius:3px;
  color:black;
  padding:3px 7px;
  width:90px;
}

.icon-bar {
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* Style the icon bar links */
.icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

.editable:hover{
  cursor: pointer;
}

.clickable:hover{
  cursor: pointer;
}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  color:black !important;
  border: 1px solid #2580EE;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-wrapper{
  position: fixed;
  top: 0;
  z-index: 9999999;
  height: 100vh;
  left: 0;
  width: 100vw;
  background: #21252999;
}

.form-container div, .form-container  span{
  font-family: Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif;
  color:black;
}

.form-container ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color:    #999;
}

.form-container :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
 color:    #999;
 opacity:  1;
}

.form-container ::-moz-placeholder { /* Mozilla Firefox 19+ */
 color:    #999;
 opacity:  1;
}

.form-container :-ms-input-placeholder { /* Internet Explorer 10-11 */
 color:    #999;
}

.form-container :placeholder-shown { /* Standard (https://drafts.csswg.org/selectors-4/#placeholder) */
color:  #999;
}

.oauth-buttons {
  text-align:center;
}

#login-form{
min-width:375px;   
}
.login-container{
width:400px;
height:330px;
display:inline-block;
margin-top: -165px;
  top: 50%;
  left: 50%;
  position: absolute;
  margin-left: -200px;
}
.form-container .create-account:hover{
opacity:.7;
}
.form-container .create-account{
color:inherit;
margin-top: 15px;
  display: inline-block;
cursor:pointer;
text-decoration:none;
}

.oauth-buttons .fa{
cursor:pointer;
margin-top:10px;
color:black;
width:30px;
height:30px;
text-align:center;
line-height:30px;
margin:5px;
margin-top:15px;
}
.oauth-buttons .fa:hover{
color:white;
}
.oauth-buttons .fa-google-plus:hover{
background: #dd4b39;
}
.oauth-buttons .fa-facebook:hover{
background:	#1b1b1b;
}

.oauth-buttons .fa-linkedin:hover{
background:	#1b1b1b;
}

.oauth-buttons .fa-twitter:hover{
background:	#55acee;
}
.oauth-buttons .fa-twitter{
  background:	#55acee;
  }

.form-container .req-input .input-status {
  display: inline-block;
  height: 40px;
  width: 40px;
  float: left;	
}
.form-container .input-status::before{
content: " ";
height:20px;
width:20px;
position:absolute;
top:10px;
left:10px;
color:white;
border-radius:50%;
background:white;
-webkit-transition: all .3s ease-in-out;
-moz-transition: all .3s ease-in-out;
-o-transition: all .3s ease-in-out;
transition: all .3s ease-in-out;

}

.form-container .input-status::after{
content: " ";
height:10px;
width:10px;
position:absolute;
top:15px;
left:15px;
color:white;
border-radius:50%;
background:#00BCD4;
-webkit-transition: all .3s ease-in-out;
-moz-transition: all .3s ease-in-out;
-o-transition: all .3s ease-in-out;
transition: all .3s ease-in-out;
}

.form-container .req-input{
width:100%;
  float:left;
position:relative;
background:#00BCD4;
height:40px;
display:inline-block;
border-radius:0px;
margin:5px 0px;
-webkit-transition: all .3s ease-in-out;
-moz-transition: all .3s ease-in-out;
-o-transition: all .3s ease-in-out;
transition: all .3s ease-in-out;
}

.form-container div .row .invalid:hover{
background:#EF9A9A;
}

.form-container div .row .invalid{
background:#E57373;
}

.form-container .invalid .input-status:before {
width:20px;
height:4px;
top:19px;
left:10px;
background:white;/*#F44336;*/
border-radius:0px;
 -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}

.form-container .invalid .input-status:after {
width:20px;
height:4px;
background:white;
border-radius:0px;
top:19px;
left:10px;
 -ms-transform: rotate(-45deg); /* IE 9 */
  -webkit-transform: rotate(-45deg); /* Chrome, Safari, Opera */
  transform: rotate(-45deg);
}

.form-container div .row  .valid:hover{
background:#2580EE;
}

.form-container div .row .valid {
background:#2580EE;

}

.form-container .valid .input-status:after {
border-radius:0px;
  width: 17px;
  height: 4px;
  background: white;
  top: 16px;
  left: 15px;
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.form-container .valid .input-status:before {
border-radius:0px;
  width: 11px;
  height: 4px;
background:white;
  top: 19px;
  left: 10px;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.form-container .input-container{
padding:0px 20px;
}

.form-container .row-input{
padding:0px 5px;
}

.form-container .req-input.input-password{
margin-bottom:0px;
}
.form-container .req-input.confirm-password{
margin-top:0px;
}

.form-container {
margin:20px;
padding: 0px 15px 20px 5px;
border-radius:0px;
background:#B3E5FC;
color:#2580EE;
-webkit-transition: all .3s ease-in-out;
-moz-transition: all .3s ease-in-out;
-o-transition: all .3s ease-in-out;
transition: all .3s ease-in-out;
text-align:center;
border-radius:8px;
}

.form-container .form-title{
font-size:23px;
color:inherit;
text-align:left;
margin-left:10px;
margin-bottom:10px;
-webkit-transition: all .3s ease-in-out;
-moz-transition: all .3s ease-in-out;
-o-transition: all .3s ease-in-out;
transition: all .3s ease-in-out;
}

.form-container .submit-row{
padding:0px 0px;
}

.form-container .btn .submit-form{
margin-top:15px;
padding:12px;
background-color:#2580EE;
color:white;
border-radius:0px;
-webkit-transition: all .3s ease-in-out;
-moz-transition: all .3s ease-in-out;
-o-transition: all .3s ease-in-out;
transition: all .3s ease-in-out;
}
.savebutton{
  margin-top:15px;
padding:12px;
background-color:#0cbb4f !important;
color:white !important;
border-radius:5px;
-webkit-transition: all .3s ease-in-out;
-moz-transition: all .3s ease-in-out;
-o-transition: all .3s ease-in-out;
transition: all .3s ease-in-out;
}
.cancelbutton{
  margin-top:15px;
padding:12px;
background-color:#c2c2c2 !important;
color:white !important;
border-radius:5px;
-webkit-transition: all .3s ease-in-out;
-moz-transition: all .3s ease-in-out;
-o-transition: all .3s ease-in-out;
transition: all .3s ease-in-out;
}


.deletebutton{
  margin-top:15px;
padding:12px;
background-color:#d41414 !important;
color:white !important;
border-radius:5px;
-webkit-transition: all .3s ease-in-out;
-moz-transition: all .3s ease-in-out;
-o-transition: all .3s ease-in-out;
transition: all .3s ease-in-out;
}

.form-container .btn.submit-form:focus{
outline:0px;
color:white;
}

.form-container .btn.submit-form:hover{
background:#00cde5;
color:white;
}

.form-container .tooltip.top .tooltip-arrow {
border-top-color:#00BCD4 !important;
}

.form-container .tooltip.top.tooltip-invalid .tooltip-arrow {
border-top-color:#E57373 !important;
}

.form-container .tooltip.top.tooltip-invalid .tooltip-inner::before{
background:#E57373;
}
.form-container .tooltip.top.tooltip-invalid .tooltip-inner{
background:#FFEBEE !important;
color:#E57373;
}

.form-container .tooltip.top.tooltip-valid .tooltip-arrow {
border-top-color:#2580EE !important;
}

.form-container .tooltip.top.tooltip-valid .tooltip-inner::before{
background:#2580EE;
}
.form-container .tooltip.top.tooltip-valid .tooltip-inner{
background:#E8F5E9 !important;
color:#2580EE;
}

.form-container .tooltip.top .tooltip-inner::before{
content:" ";
width:100%;
height:6px;
background:#2580EE;
position:absolute;
bottom:5px;
right:0px;
}
.form-container .tooltip.top .tooltip-inner{
border:0px solid #2580EE;
background:#E0F7FA !important;
color:#2580EE;
font-weight:bold;
font-size:13px;
border-radius:0px;
padding:10px 15px;
}
.form-container .tooltip {
max-width:150px;
opacity:1 !important;
}

.form-container .message-box{
width:100%;
height:auto;
}

.form-container textarea:focus,.form-container textarea:hover{
background:#fff;
outline:none;
border:0px;
}

.form-container .req-input textarea {
max-width:calc(100% - 50px);
  width: 100%;
  height: 80px;
  border: 0px;
  color: #777;
  padding: 10px 9px 0px 9px;
float:left;

}
.form-container input[type=text]:focus, .form-container input[type=password]:focus, .form-container input[type=email]:focus, .form-container input[type=tel]:focus, .form-container select{
  background:#fff;
color:#777;
border-left:0px;
outline:none;
}

.form-container input[type=text]:hover,.form-container input[type=password]:hover,.form-container input[type=email]:hover,.form-container input[type=tel]:hover, .form-container select{
background:#fff;
}

.webzy-contact-fields > .form-container input[type=text], .form-container input[type=password], .form-container input[type=email],input[type=tel], form-container select{
width:calc(100% - 50px);
float:left !important;
border-radius:0px;
border:0px solid #ddd;
padding:0px 9px;
height:40px;
line-height:40px;
color:#777;
background-color:none !important;
-webkit-transition: all .3s ease-in-out;
-moz-transition: all .3s ease-in-out;
-o-transition: all .3s ease-in-out;
transition: all .3s ease-in-out;
}

.templaterow{
  margin-top:30px;
}

.modal-row{
  margin-bottom:10px;
}


.sortitem {
  padding: 4px 15px 4px 24px;
  margin: 20px;
  background-color: #ffffffe0;
  border-radius: 4px;
}

.modalrow{padding-top:30px;
}
.close-button{
  cursor:pointer;
  font-size:'14px';
  font-weight:'700'
}
.modal-head{
  background-color: #0077ff;
  margin-left:0px;
  padding:5px 0px 5px 30px;
  font-size:18px;
  border-radius:0px;
  color:white;
}
.modal-headdd{
  background-color: #0077ff;
  margin-left:110px;
  padding:5px 130px 5px 160px;
  font-size:28px;
  color:white;
}

.modal-headd{
  background-color: #0077ff;
  margin-left:0px;
  padding:5px 0px 5px 0px;
  font-size:18px;
  border-radius:0px;
  color:white;
}

.subscriptt{
  font-size:11px;
}
.field-names{
  padding-top:14px;
}

.field-row{
  padding-top:10px;
}

.icons{
  
  margin-right:3px;
  margin-left:8px;

}

.fa-home{
  height:2px;
}
.submit{
  margin-top:0px;
  background-color: white;
  border-radius:5px;
  padding:8px;
  font-size:13px;
  box-shadow: 1px 1px 6px 0px #888888;
}

.footer-row{
margin-top:40px;
}

.copyright-row{
  padding: 20px 0px;
  font-size:15px;
  font-weight: 600;
  font-family:montserrat;
}


.fieldd-row{
  margin-top:2px;
}

.field-row{
  margin-top:3px;
}
.head-row{
  margin:0px 20px 0px 20px;
}

.form-text{
  color:#495057;
  font-size:20px;
}

.gobutton{
    float: right;
    margin-top: 10%;
    border: none;
    border-radius: 1.5rem;
    padding: 10px 4px;
    background: #0062cc;
    color: #fff;
    font-weight: 600;
    width: 110%;
    cursor: pointer;
}

.fieldtext{
  color:grey;
  font-size:18px;
}

.editclas{
  margin-left:720px;
  margin-top:40px;
}

.editcla{
  margin-left:720px;
  margin-top:40px;
  color:black;
}

.modal-section{
  height:600px;
}

.btn-close-right{
position: absolute;
top: 50%;
right: 0%;
z-index: 999;

}
.btn-close-right-active{
  position: absolute;
  top: 50%;
  right: 8%;
  z-index: 999;
  
  }

.btn-close-left{
  position: absolute;
  top: 50%;
  left: 0%;
  z-index: 999;
}

.btn-close-left-active{
  position: absolute;
  top: 50%;
  left: 8%;
  z-index: 999;
}

.home-intro{
  
  font-size:39px;
  font-weight:800;
  margin:-130px 30px 50px 30px;
  font-family: 'lato', sans-serf
}
.home-introo{
  line-height:45.8px;
  font-size:39px;
  font-weight:800;
  margin:10px 140px 50px 140px;
  font-family: 'lato', sans-serf
}
.block-image{
  padding-top:20px;
}
#secNav{

height:100px;
}
.secpanel-item{
  font-size:15px;
  font-weight:200;
}

.divhead{
  
  font-size:44px;
  color:grey;
  margin-bottom:80px;
}
.webzycard{
  border: #0077b5;

  box-shadow: 3px 2px 9px 1px #d5dfd8;
}

.webzylo
.webzy-nav-top{
  border-bottom:1px solid rgb(243, 243, 243) !important;
}

.webzy-nav-second{
  
}


.webzycardhead{
  font-weight:500;
  font-size:22px;
  color:rgb(70, 70, 70);
  margin:30px;
}

.sub-row{
  margin-top:40px;
}
.webzycard-text{
  font-weight:200;
  font-size:18px;
  color:rgb(70, 70, 70);
  padding-bottom:30px;
}

.whatwebzy{
  margin-top:30px;
}

.whywebzy{
  margin-top:30px;
  
  
}

.why{
  background-image: url("../img/whywebzy.png");
  height:900px;
}
/***8888888888888888*/
section {
  padding: 60px 0;
}

section .section-title {
  text-align: center;
  color: #007b5e;
  margin-bottom: 50px;
  text-transform: uppercase;
}
#footer1 {
  margin-top:60px;
  background-image: url("../img/footerback.png");
  
}
#footer1 h5{
padding-left: 10px;
  border-left: 3px solid #eeeeee;
  padding-bottom: 6px;
  margin-bottom: 20px;
  color:#585858;
  font-weight:600;
}
#footer1 a {
  color:#585858;
  text-decoration: none !important;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  font-weight:600;
}
#footer1 ul.social li{
padding: 3px 0;
}
#footer11 ul.social li a i {
  margin-right: 5px;
font-size:25px;
-webkit-transition: .5s all ease;
-moz-transition: .5s all ease;
transition: .5s all ease;
}
#footer1 ul.social li:hover a i {
font-size:30px;
margin-top:-10px;
}
#footer1 ul.social li a,
#footer1 ul.quick-links li a{
color:#585858;
font-weight:600;
}
#footer1 ul.quick-links li u{
  color:#585858;
  font-weight:600;

  }
#footer1 ul.social li a:hover{
color:#585858;
font-weight:600;
}
#footer1 ul.quick-links li{
padding: 3px 0;
-webkit-transition: .5s all ease;
-moz-transition: .5s all ease;
transition: .5s all ease;
}
#footer1 ul.quick-links li:hover{
padding: 3px 0;
margin-left:5px;
font-weight:700;
}
#footer1 ul.quick-links li a i{
margin-right: 5px;
}
#footer1 ul.quick-links li:hover a i {
  font-weight: 700;
}

@media (max-width:767px){
#footer1 h5 {
  padding-left: 0;
  border-left: transparent;
  padding-bottom: 0px;
  margin-bottom: 10px;
  font-weight:600;
}
}

.text-copyright{
  color:#585858;
  font-weight:600;
}
.get-in-touch {
  max-width: 800px;
  margin: -60px auto;
  position: relative;

}
.get-in-touch .title {
  text-align: center;
  
  letter-spacing: 3px;
  font-size: 3.0em;
  line-height: 48px;
  padding-bottom: 48px;
     color:#808080;
    background: rgb(172, 219, 60);
  font-family:'lato', sans-serif;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
}

.get-in-touch .subtitle {
  text-align: center;
  font-family:'lato', sans-serif;
  letter-spacing: 0px;
  font-size: 2.4em;
  font-weight:400;
  line-height: 48px;
  padding-bottom: 48px;
     color: #808080;
    background:#808080;
  
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
}

.contact-form .form-field {
  position: relative;
  margin: 32px 0;
}
.contact-form .input-text {
  display: block;
  width: 100%;
  height: 24px;
  border-width: 0 0 2px 0;
  border-color: #afafaf;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
}
.contact-form .input-text:focus {
  outline: none;
}
.contact-form .input-text:focus + .label,
.contact-form .input-text.not-empty + .label {
  -webkit-transform: translateY(-24px);
          transform: translateY(-24px);
}
.contact-form .label {
  position: absolute;
  left: 20px;
  bottom: 14px;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: #39393b;
  cursor: text;
  transition: -webkit-transform .2s ease-in-out;
  transition: transform .2s ease-in-out;
  transition: transform .2s ease-in-out, 
  -webkit-transform .2s ease-in-out;
}
.contact-form .submit-btn {
  display: inline-block;
  background-color:rgb(171, 219, 57);
   
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 16px;
  padding: 8px 16px;
  border: none;
  width:200px;
  cursor: pointer;
}



.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#0C9;
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
}

.my-float{
	margin-top:22px;
}

.register1{
  background-color: #0077b5;
}

.googlebtn > button{
  width: 100%;
}


/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}


.prod-image{
  margin:10px 0px;
  height:20rem;
  width:28rem;
  border-radius:5px;
}

.profile-pic{
  width: 32px;
  height: 33px;
}

.profile-dropdown-trigger:hover  > .profile-dropdown
{
  display: block !important;
  background-color: #007b5e;

}

.register-background{
  margin-top:-80px;
  padding:120px 60px;
}

.register{
  padding:10px 60px;
}

#published .editclass{
  display:none;
}
#mainPage .oneditShow{
  display:none;
}

#published .editclas{
  display:none;
}
#published .navbar{
  margin-top:0px;

}

#published .navbar-second{
  margin-top:0px;
  border-top:1px solid rgb(247, 247, 247);

}
.cross{
  height:8px;
}

section{
  background-size:cover;
}

.timeline-card{
  padding:40px;
}

.react-confirm-alert-overlay{

  background: #000 !important;
}


/* SIDEBAR  */


.sidenav {
  height: 100%;
  width: 0px;
  position: fixed;
  z-index: 1;
  top: 19px;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #313131;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #0c0c0c;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}
.closebtn{
  cursor:pointer
}
.closebutton:hover{
  cursor:pointer;
}
#main {
  transition: margin-left .5s;
  padding: 16px;
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}

.sidebar-brand{


  background: #BFF31D;
  border-right:2px #ffffff solid;
  height: 100%;
  overflow-y: hidden;

}

.sidebar-list{
  list-style: none;
  font-size: 18px;
  padding: 1.2em;
  color: #5e5c5c;
  margin-left: -40px;
  width: 128%;
  border-bottom: 2px rgb(181, 226, 75) solid;
  line-height: 1px;
  transition:font-size 2s ease-in-out;
}
.sidebar-list:hover{
  list-style: none;
  font-size: 18px;
  padding: 1.2em ;
  background-color: rgba(255, 255, 255, 0);
  color: #0a0a0a;
  margin-left: -40px;
  width: 128%;
  border-bottom: 2px rgb(181, 226, 75) solid;
  line-height: 1px;
}



@media (max-width: 767.98px) {

  .why {
    background-image: url(/static/media/whywebzy.e5a46590.png);
   height: 100%;
}


  .hide-mobile{
    display: none;
  }

  .home-introo {
    line-height: 24.8px;
    font-size: 50%;
    font-weight: 800;
    margin: 5px;
    /* top: 16px; */
    font-family: 'lato', sans-serf;
}

.home-mobile-box{
  background: blue;
  padding: 2px 2px;
  margin-top: 110px;
}
}

@media (min-width: 767.98px) {

 
.hide-desktop{
  display: none;
}
}


@media (max-width: 768px) {
  section {
    padding: 110px 0;
  }
.editclass{
  display:none;
}
  .sortSection{
    display:none;
  }
  .addSection{
    display:none;
  }

  .timeline:after{
    display:none;
  }
  .oneditShow{
    display:none;
  }

  .timeline > li .timeline-panel {
    position: relative;
    float: right;
    width: 100%;
    padding: 0 40px 0 100px;
    text-align: left;
  }
}

.second-navbar{
  margin-left:134px;
}


.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.addPageButton{
  background-color: rgb(107, 199, 241);
  font-size:14px;
  font-family:montserrat;

}

.bannerButton{
 

}

html {
  scroll-behavior: smooth;
}
.aboutus-banner{

}
.aboutus-text{
  font-size:15px;
  font-family:montserrat;
}



.webzy-contact-fields{
  padding:10px 20px !important;
  margin-bottom:0px !important;
}


.webzy-contact-card-1 {
 
  padding: 49px 30px;
  border-radius: 3px;
}

.contact-details {
  font-size: 15px;
  margin-top: 32px;
  font-weight: 700;
  color: #245883;
}

.webzy-contact-fields > .form-control {
  display: block;
  width: 100%;
  height: 55px;
 
  font-size: 14px;
  line-height: 1.42857143;
  color: rgb(221, 221, 221) !important;
  background-color: #ffffff00;
  background-image: none;
  border: 2px solid rgb(255 255 255) !important;
  border-radius: 4px;
  font-family: montserrat,'sans-serif';
  box-shadow: none !important;
}

.webzy-contact-fields > .form-control::placeholder{
  color:white;
  font-weight:600;

}
.webzy-query-fields > .form-control::placeholder{
  color:white;
  font-weight:600;
}


.webzy-query-fields > .form-control {
  display: block;
  width: 100%;
  height: 55px;
  text-align:center;
  font-size: 14px;
  line-height: 1.42857143;
  color: rgb(221, 221, 221);
  background-color: #ffffff00;
  background-image: none;
  border: 2px solid #e7e7e7 !important;
  border-radius: 4px;
  font-family: montserrat,'sans-serif';
  box-shadow: none !important;
}




/* .webzy-contact-fields > input[type=password],input[type=email], input[type=phone],input[type=text], textarea {
  padding: 24px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 5px;
  width: 85%;
  transition: all .5s ease-in-out;
  border-radius: 5px;

  background-color: rgba(255, 255, 255, 0) !important;
  color: rgb(194, 194, 194);
  border: 1px solid #edebeb;
} */
.contact-description {
  font-size: 15px;
  line-height: 1.75;
  font-weight: 600;
  color: #2f2f2f;
  font-family: montserrat;
}
.contact-head {
  font-weight: 600;
  
  color: #0a2f4d;
  margin: 0px 0px 24px 0px;
  font-size: 30px;
  font-family:sans-serif;
}

.query-head {
  font-weight: 600;
  
  color: #0a2f4d;
  margin: 0px 0px 24px 0px;
  font-size: 30px;
  font-family:sans-serif;
}

.query-subhead {
  font-weight: 600;
  color: #363f46;
  margin: 0px 0px 34px 0px;
  font-size: 22px;
  font-family:sans-serif;
}
.contactdiv{
  background: none ;
}
.contactdiv input{
  background: none !important;
}

.webzy-contact-card {
  background-image: linear-gradient(#1682d7, #1682d7);
  text-align: center;
  padding: 40px 10px;
  border-radius: 3px;
  box-shadow: -10px 7px 20px 3px #9ba0a5;
}

.webzy-loader-show{
  display:block;
  text-align:center;
}

.loader{
  height:300px;
  
}

.delete-disabled-text{
  color:red;
  font-size:15px;
}