.breadcrumbitem{
  font-size:16px;
  color:rgb(255, 255, 255) !important;
  display:inline;
  margin-right: -4px;
  padding-left:2px;
  padding-right:0px;
 
  font-family:raleway,'sans-serif';
  text-align: left;
}



.breadcrumb li {
  display:inline;
  

}
.breadcrumb{

    position: relative;
    margin-top: 10px;
   
    height: 40px;
    top: -10;
    z-index: 0;
    background:#ffffff;
    }
    .breadcrumb {
      text-align: center;
      display: block;
      padding: 8px 1px;
      overflow: hidden;
      border-radius: 2px;
      counter-reset: flag;
      margin-left: -28px;
      font-family:montserrat;
      margin-top: 0px;
      width:auto;
      box-shadow: 0px 1px 9px 0px #f7f5f5;
    
  }
    
    .breadcrumb li {
    display:inline;
    margin-right: 10px;
    
    }

     :root {
  --breadcrumb-theme-1: #0065A0;
  --breadcrumb-theme-2: #fff;
  --breadcrumb-theme-3: #0065A0;
  --breadcrumb-theme-4: #0065A0;
  }



.breadcrumb__step {
    text-decoration: none;
    outline: none;
    display: block;
    float: left;
    font-weight: 500;
    font-family: montserrat,'sans-serif';
   
    font-size: 14px;
    
    padding: 0 10px 0 20px;
    position: relative;
    background: #ffffff;
    color: #696868;
    transition: background 0.5s;
}
        .breadcrumb__step:first-child {
        padding-left: 15px;
        border-radius: 5px 0 0 5px;
        }
        .breadcrumb__step:first-child::before {
        left: 14px;
        }
        .breadcrumb__step:last-child {
        border-radius: 0 5px 5px 0;
        padding-right: 20px;
        }
        .breadcrumb__step:last-child::after {
        content: none;
        }
        /* .breadcrumb__step::before {
        content: counter(flag);
        counter-increment: flag;
        border-radius: 100%;
        width: 20px;
        height: 20px;
        line-height: 20px;
        margin: 8px 0;
        position: absolute;
        top: 0;
        left: 30px;
        font-weight: bold;
        background: var(--breadcrumb-theme-2);
        box-shadow: 0 0 0 1px var(--breadcrumb-theme-1);
        } */
        .breadcrumb__step::after {
          content: '';
          position: absolute;
          top: 5px;
          right: -6px;
          width: 11px;
          height: 11px;
          transform: scale(0.707) rotate(45deg);
          z-index: 1;
          border-radius: 0 5px 0 50px;
          background: #ffffff;
          transition: background 0.5s;
          box-shadow: 2px -2px 0 2px #52ba9b;;
      }
        .breadcrumb__step--active {
        color: white;
        background: #1E5B81;
        }
        .breadcrumb__step--active::before {
        color: rgb(255, 255, 255);
        }
        .breadcrumb__step--active::after {
        background: rgb(255, 255, 255);
        }
        .breadcrumb__step:hover {
        
        
        cursor: disabled;
        }
        .breadcrumb__step:hover::before {
        color: white;
        }
        .breadcrumb__step:hover::after {
        color: white;
        background: #ffffff;
        }
        .breadCrumb1-row {
          margin: 24px 0px 15px -16px;
          padding: 4px 0px 4px 16px;
          
          width: auto;
          background-color: #1D5A81;
          border-radius: 2px;
          font-family: montserrat;
          color: white;
          font-size: 17px;
          
          width: 79vw;
      }